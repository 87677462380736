import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import AuthNavbar from "../AuthNavbar"
import { getPostReviewList } from "../../actions/reviewActions"
import { Outlet } from "react-router-dom"
import { useAuthStore } from '../../store/store';

const PostReviewPage = () => {
    
    const data = useAuthStore(state => state.user)
    const [reviewList, setReviewList] = useState([])
    const [listedUser, setListedUser] = useState({})

    useEffect(() => {
        getPostReviewList(data.user._id).then(res => {
            setReviewList(res)
        })
    }, [])

    const displayList = () => {
        return reviewList.map((r, i) => (
            <div className="" key={i}>
                <div className="d-flex justify-content-between align-items-center border border-dark rounded p-2 my-3">
                    <div className="fs-3">
                        {r.userId.firstName} {r.userId.lastName}
                    </div>
                    <div>
                        <Link to={`${r.profileId}`} state={r.userId} className="text-white text-decoration-none">
                            <button onClick={() => {
                            setListedUser(r.profileId)}} className="btn btn-primary rounded-pill">
                                View
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        ))
    }

    return(
        <AuthNavbar>
            <div className="py-3 container-fluid">
                <div align="center" className="display-3">
                    List of users to review
                    <div>
                        <Link to="/review-lists">
                            <button className="btn btn-outline-success ">
                                View Review
                            </button>
                        </Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        {displayList()}
                        {listedUser.userId !== undefined ? 
                        <div className="container-fluid">
                            <div className="">
                                <span className="fs-3">{listedUser?.userId.firstName} {listedUser?.userId.lastName}</span>
                            </div>
                            <div className="">
                                Public Score: {listedUser?.userId.publicScore}
                            </div>
                            <div>
                                Expert Score: {listedUser?.userId.expertScore}
                            </div>
                        </div> : null}
                    </div>
                    <div className="col-md-8 p-md-5 p-3">
                        <Outlet/>
                    </div>
                </div>
            </div>
        </AuthNavbar>
    )
}

export default PostReviewPage