import { useParams, useLocation, useNavigate } from "react-router-dom"
import { useEffect, useState, useRef } from "react"
import { Link } from "react-router-dom"
import AuthNavbar from "../AuthAccess/AuthNavbar"
import { CircularProgress } from "@mui/material"
import {apiUrl} from "../constants";

const SinglelBlog = () => {

    let {blogId} = useParams()

    useEffect(() => {
        getDevToBlogs()
    }, [])

    const [devToBlogs, setDevToBlogs] = useState([])
    const [loading, setLoading] = useState(true)
    const getDevToBlogs = () => {
        fetch(`${apiUrl}/get-dev-to-single-blog/${blogId}`, {
            method: 'GET'
        }).then(response => response.json())
        .then(data => {
            setDevToBlogs(data)
            setLoading(false)
        }).catch(err => {
            console.log(err);
        });
    }

    const sanitizedRef = useRef();

    useEffect(() => {
        const div = document.createElement('div');
        div.innerHTML = devToBlogs?.body_html;
    
        // Remove the div element with class "ltag__user ltag__user__id__18254"
        const unwantedDiv = div.querySelector('.ltag__user');
        if (unwantedDiv) {
          div.removeChild(unwantedDiv);
        }

        const imgElements = div.querySelectorAll('img');
            imgElements.forEach((img) => {
            img.classList.add('img-fluid');
        });

        if (sanitizedRef.current) {
          sanitizedRef.current.innerHTML = '';
          while (div.firstChild) {
            sanitizedRef.current.appendChild(div.firstChild);
          }
        }
      }, [devToBlogs]);
    
    return (
        <AuthNavbar>
            <div className="py-5">
                <div className="col-md-6 col-10">
                </div>
                {loading ? <div className="text-center">
                    <CircularProgress/>
                </div> : 
                <div className="d-flex flex-wrap justify-content-center align-items-center">
                    <div className="card border-0 bg-transparent col-md-6 col-10">
                <div align="left">
                    <Link to="/blogs">
                        <button className="btn btn-outline-secondary">
                            Go Back
                        </button>
                    </Link>
                </div>
                        <div className="display-4 fw-normal py-3">
                            {devToBlogs.title}
                        </div>
                        <div ref={sanitizedRef} />
                    </div>
                </div>}
                <div className="text-center py-md-5 py-3 text-danger">
                    <span className="">Site under development stay tuned!!!!</span>
                </div>
            </div>
        </AuthNavbar>
    )
}

export default SinglelBlog