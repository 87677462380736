import {apiUrl} from './../constants'

export const getReviewList = (userId) => {
    return fetch(`${apiUrl}/get-review-list/${userId}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(response => {
        return response.json()
    }).catch(err => 
        console.log("Get User List: ", err)
    )
}

export const getPostReviewList = (userId) => {
    return fetch(`${apiUrl}/get-post-review-list/${userId}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(response => {
        return response.json()
    }).catch(err => 
        console.log("Get User List: ", err)
    )
}

export const getSingleReviewUser = (rId) => {
    return fetch(`${apiUrl}/get-single-review-user/${rId}`, {
        method: "GET",
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
    }).then(response => {
        return response.json()
    }).catch(err => {
        console.log("SINGLE REVIEW ERR: ",err)
    })
}

export const getSinglePostReviewUser = (rId) => {
    return fetch(`${apiUrl}/get-single-post-review-user/${rId}`,{
        method: "GET",
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
    }).then(response => {
        return response.json()
    }).catch(err => {
        console.log(err)
    })
}

export const submitScore = (data) => {
    return fetch(`${apiUrl}/post-score`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-type': 'application/json'
        },
        body: JSON.stringify(data)
    }).then(response => {
        return response.json()
    }).catch(err => {
        console.log(err)
    })
}

export const getScore = () => {
    return fetch(`${apiUrl}/get-scores`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-type': 'application/json'
        },
    }).then(response => {
        return response.json()
    }).catch(err => {
        console.log(err)
    })
}