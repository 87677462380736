import {apiUrl} from './../constants'

export const createTask = (data) => {
    return fetch(`${apiUrl}/create-task`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    }).then(response => {
        return response.json()
    }).catch(err => {
        return err.json()
    })
}

export const getProjectTasks = (data) => {
    return fetch(`${apiUrl}/get-project-tasks/${data}`, {
        method: "GET",
        headers: {
            Accept: 'application/json',
            'Content-Type': 'appliccation/json'
        }
    }).then(response => {
        return response.json()
    }).catch(err => {
        return err.json()
    })
}

export const updateTask = (data, tId) => {
    return fetch(`${apiUrl}/update-task/${tId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-type': 'application/json'
        },
        body: JSON.stringify(data)
    }).then(response => {
        return response.json()
    }).catch(err => {
        return err.json()
    })
}

export const deleteTask = (tId) => {
    return fetch(`${apiUrl}/delete-task/${tId}`, {
        method: 'PATCH',
        headers: {
            Accept: 'application/json',
            'Content-type': 'application/json'
        },
    }).then(response => {
        return response.json()
    }).catch(err => {
        return err.json()
    })
}

export const memberAcceptTask = (tId) => {
    return fetch(`${apiUrl}/member-accept-task/${tId}`, {
        method: 'PATCH',
        headers: {
            Accept: 'application/json',
            'Content-type': 'application/json'
        },
    }).then(response => {
        return response.json()
    }).catch(err => {
        return err.json()
    })
}

export const memberCompleteTask = (tId) => {
    return fetch(`${apiUrl}/member-complete-task/${tId}`, {
        method: 'PATCH',
        headers: {
            Accept: 'application/json',
            'Content-type': 'application/json'
        },
    }).then(response => {
        return response.json()
    }).catch(err => {
        return err.json()
    })
}

export const pushUpgrade = (taskUpdates, tId) => {
    return fetch(`${apiUrl}/push-upgrade/${tId}`, {
        method: 'PATCH',
        headers: {
            Accept: 'application/json',
            'Content-type': 'application/json'
        },
        body: JSON.stringify(taskUpdates)
    }).then(response => {
        return response.json()
    }).catch(err => {
        return err.json()
    })
}

export const markRequirementDone = (data) => {
    return fetch(`${apiUrl}/mark-requirement-done/${data.taskId}`, {
        method: 'PATCH',
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json'
        }, 
        body: JSON.stringify(data)
    }).then(response => {
        return response.json()
    }).catch(err => {
        return err.json()
    })
}