import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import CircularProgress from '@mui/material/CircularProgress';
import AuthNavbar from "../AuthAccess/AuthNavbar";
import { apiUrl } from "../constants";
import Navbar from "../authFlow/Navbar";

const BlogLists = () => {

    useEffect(() => {
        getDevToBlogs()
    }, [])

    const [devToBlogs, setDevToBlogs] = useState([])
    const [loading, setLoading] = useState(true)

    const getDevToBlogs = () => {
        fetch(`${apiUrl}/get-dev-to-blogs`, {
            method: 'GET'
        }).then(response => response.json())
            .then(data => {
                setDevToBlogs(data)
                setLoading(false)
            }).catch(err => {
                console.log(err);
            });
    }

    return (
        <>
            <div className='py-2 sticky-top'>
                <Navbar />
            </div>
            <div className="py-5">
                <div className="">
                    <div className="d-flex flex-wrap justify-content-left align-items-center">
                        <Link to="/">
                            <button className="btn btn-outline-secondary">
                                Go Back
                            </button>
                        </Link>
                    </div>
                    <div className="text-center fw-normal display-1">
                        Blogs
                    </div>
                </div>
                <div className="d-flex flex-wrap justify-content-center align-items-center">
                    {loading ? <div className="py-5">
                        <CircularProgress />
                    </div> :
                        <div className="col-md-8 col-10 d-flex flex-wrap justify-content-center">
                            {devToBlogs.map((d, i) => (
                                <div key={i} className="card col-md-5 bg-transparent p-2 my-3 m-md-2 border-primary">
                                    <Link to={`/single-blog/${d.id}`}>
                                        <div className="fs-3">{d.title}</div>
                                    </Link>
                                    <div>{d.description}</div>
                                </div>
                            ))}
                        </div>}
                </div>
                <div className="text-center py-md-5 py-3 text-danger">
                    <span className="">Site under development stay tuned!!!!</span>
                </div>
            </div>
        </>
    )
}

export default BlogLists