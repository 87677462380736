import { useEffect, useState } from "react"
import { useAuthStore } from '../../store/store';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/system';
import { useParams, useLocation, useNavigate, json } from "react-router-dom"
import { getScore, getSingleReviewUser } from "../../actions/reviewActions"
import { Collapse, capitalize } from '@mui/material';
import { submitScore } from "../../actions/reviewActions";
import { useOutletContext } from "react-router-dom";

const CssTextField = styled(TextField)({

    '& label': {
        color: "#0275d8"
    },
    '& input': {
        color: "black"
    },
    '& .MuiInputBase-multiline': {
        color: "black"
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#0275d8',
        color: "black"
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#0275d8',
        },
        '&:hover fieldset': {
            borderColor: '#0275d8',
        },
    },
});

const SingleUserReview = () => {

    const data = useAuthStore(state => state.user)
    let { rId } = useParams()
    let userData = useLocation().state
    const [education, setEducation] = useState([])
    const [work, setWork] = useState([])
    const [otherLinks, setOtherLinks] = useState([])

    const navigate = useNavigate()

    const [isOpen, setIsOpen, setRefresh] = useOutletContext()

    const [displayEducation, setDisplayEducation] = useState(true)
    const [displayWork, setDisplayWork] = useState(false)
    const [displayLinks, setDisplayLinks] = useState(false)
    const [displayStackOverflow, setDisplayStackOverflow] = useState(false)
    const [displayGithub, setDisplayGithub] = useState(false)
    const [displayRepos, setDisplayRepos] = useState(false)
    const [displayOverview, setDisplayOverview] = useState(false)
    const [displayReviewForm, setDisplayReviewForm] = useState(false)

    const [count, setCount] = useState(0)

    const [stackOverFlowProfile, setStackOverFlowProfile] = useState(null)
    const [githubProfile, setGithubProfile] = useState(null)
    const [githubRepos, setGithubRepos] = useState([])

    useEffect(() => {
        getSingleReviewUser(rId).then(res => {
            setEducation(res.educationExprience)
            setCount(0)
            setWork(res.workExprience)
            setOtherLinks(res.otherLinks)
            // getStackData(res?.stackOverFlowProfile)
            // getGitData(res.githubProfile)
            createSudoArrayEdu(res.educationExprience)
            createSudoArrayWork(res.workExprience)
            createSudoArrayOtherLink(res.otherLinks)
        })
        if (isOpen === false) {
            navigate('/review-lists')
        }
        // console.log(getScore())
    }, [rId])


    const getStackData = (userId) => {
        // console.log(userId)
        if (userId !== null || userId !== '') {
            fetch(`https://api.stackexchange.com/2.3/users/${userId}?site=stackoverflow`).then(res => {
                console.log("STACK DATA", res)
                res.json().then(data => {
                    if (data?.items[0] === undefined) {
                        setStackOverFlowProfile(undefined)
                    } else {
                        setStackOverFlowProfile(data?.items[0])
                    }
                })
            }).catch(err => {
                console.log(err)
            })
        }
    }

    const getGitData = (userName) => {
        // console.log(userName)
        if (userName !== null || userName !== '') {
            fetch(`https://api.github.com/users/${userName}`).then(res => {
                console.log("GIT DATA", res)
                res.json().then(res => {
                    setGithubProfile(res)
                    fetch(res.repos_url).then(res => {
                        res.json().then(res => {
                            setGithubRepos(res)
                        })
                    })
                })
            })
        }
    }

    const [values, setValues] = useState({
        score: '',
        error: false,
        errorMessage: ''
    })

    const { score, error, errorMessage } = values

    const [reviewValues, setReviewValues] = useState({
        review: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
        reviewError: false,
        reviewErrorMessage: ''
    })

    const { review, reviewError, reviewErrorMessage } = reviewValues

    const createSudoArrayEdu = (education) => {
        const newArr = education.map((e, i) => {
            return {
                entityId: e._id,
                entityTitle: e.eduTitle,
                entityDesc: e.eduDescription,
                entityScore: ``
            }
        })
        setEduScore(newArr)
    }

    const createSudoArrayWork = (work) => {
        const newArr = work.map((e, i) => {
            return {
                entityId: e._id,
                entityTitle: e.title,
                entityDesc: e.description,
                entityScore: ``
            }
        })
        setWorkScore(newArr)
    }

    const createSudoArrayOtherLink = (work) => {
        const newArr = work.map((e, i) => {
            return {
                entityId: e._id,
                entityTitle: e.linkTitle,
                entityScore: ``
            }
        })
        setOtherLinksScore(newArr)
    }

    const updateExistingAnswer = function (arr, attr, value) {
        var i = arr.length;
        while (i--) {
            if (arr[i] && arr[i].hasOwnProperty(attr) && (arguments.length > 2 && arr[i][attr] === value.entityId)) {
                arr[i].entityScore = value.entityScore
                return arr;
            }
        }
    }

    const [eduScore, setEduScore] = useState([])
    const [scoreObjectEdu, setScoreObjectEdu] = useState([])

    const [workScore, setWorkScore] = useState([])
    const [scoreObjectWork, setScoreObjectWork] = useState([])

    const [otherLinksScore, setOtherLinksScore] = useState([])
    const [scoreObjectOtherLinks, setScoreObjectOtherLinks] = useState([])

    const [stackOverflowScore, setstackOverflowScore] = useState('')
    const [githubScore, setGithubScore] = useState('')

    const handleChange = name => event => {
        if (event.target.value > 10 || event.target.value < 0) {
            setValues({ ...values, error: true, errorMessage: 'Score in range 0-10' });
        } else {
            setValues({ ...values, error: '', errorMessage: '', [name]: event.target.value });
        }
    };

    const handleReviewChange = name => event => {
        setReviewValues({ ...reviewValues, reviewError: '', reviewErrorMessage: '', [name]: event.target.value });
    }

    // ----------------handle Education Score------------------

    const handleSubmitIndiScoreEdu = (data) => {
        setScoreObjectEdu(updateExistingAnswer(eduScore, 'entityId', data))
        setValues({ ...values, score: ``, error: false, errorMessage: "" })
    }

    const setFormDataEdu = ({ id, formCount }) => {
        for (let i = 0; i < scoreObjectEdu.length; i++) {
            setValues({ ...values, score: scoreObjectEdu[formCount - 1].entityScore, error: "", errorMessage: "" })
        }
    }

    const setFormDataEduNext = ({ id, formCount }) => {
        for (let i = 0; i < scoreObjectEdu.length; i++) {
            setValues({ ...values, score: scoreObjectEdu[formCount + 1].entityScore, error: "", errorMessage: "" })
        }
    }

    // ----------------handle Work Score------------------

    const handleSubmitIndiScoreWork = (data) => {
        setScoreObjectWork(updateExistingAnswer(workScore, 'entityId', data))
        setValues({ ...values, score: ``, error: false, errorMessage: "" })
    }

    const setFormDataWork = ({ id, formCount }) => {
        for (let i = 0; i < scoreObjectWork.length; i++) {
            setValues({ ...values, score: scoreObjectWork[formCount - 1].entityScore, error: "", errorMessage: "" })
        }
    }

    const setFormDataWorkNext = ({ id, formCount }) => {
        for (let i = 0; i < scoreObjectWork.length; i++) {
            setValues({ ...values, score: scoreObjectWork[formCount + 1].entityScore, error: "", errorMessage: "" })
        }
    }

    // ----------------handle Other Links Score------------------

    const handleSubmitIndiScoreOtherLinks = (data) => {
        setScoreObjectOtherLinks(updateExistingAnswer(otherLinksScore, 'entityId', data))
        setValues({ ...values, score: ``, error: false, errorMessage: "" })
    }

    const setFormDataOtherLinks = ({ id, formCount }) => {
        for (let i = 0; i < scoreObjectOtherLinks.length; i++) {
            setValues({ ...values, score: scoreObjectOtherLinks[formCount - 1].entityScore, error: "", errorMessage: "" })
        }
    }

    const setFormDataOtherLinksNext = ({ id, formCount }) => {
        for (let i = 0; i < scoreObjectOtherLinks.length; i++) {
            setValues({ ...values, score: scoreObjectOtherLinks[formCount + 1].entityScore, error: "", errorMessage: "" })
        }
    }

    const handleScoreSubmit = () => {
        submitScore({
            userId: userData._id,
            reviewerId: data.user._id,
            profileId: rId,
            educationScore: scoreObjectEdu,
            workScore: scoreObjectWork,
            otherLinkScore: scoreObjectOtherLinks,
            stackOverflowScore,
            githubScore,
            review,
            scoreEduPercent: calculatePercentage(scoreObjectEdu),
            scoreWorkPercent: calculatePercentage(scoreObjectWork)
        }).then(res => {
            if (res) {
                navigate('/review-lists')
                setIsOpen(false)
                setRefresh(true)
            } else {
                console.log("error")
            }
        })
    }

    const calculatePercentage = (scoreObject) => {
        let sumScore = 0
        let total = scoreObject.length * 10
        for (let i = 0; i < scoreObject.length; i++) {
            sumScore += parseInt(scoreObject[i].entityScore)
        }
        return (sumScore * 100) / total
    }

    const viewEducation = () => {
        return (
            <div className="">
                <div className="fs-2">
                    Education
                </div>
                <div className="border border-primary p-2 rounded">
                    {education.length === 0 ? <div>
                        <div>NO Entries</div>
                    </div> :
                        <div>
                            <div className="row d-flex justify-content-between align-items-center">
                                <div className="col-md">
                                    <div className="d-flex align-items-end">
                                        <div className="fs-3">{count + 1}. {education[count]?.eduTitle}</div>
                                    </div>
                                    <div>
                                        <p className="fs-4">Description: {education[count]?.eduDescription}</p>
                                    </div>
                                </div>
                                <div className="col-md d-flex justify-content-center align-items-center">
                                    <div className="d-flex align-items-center">
                                        <CssTextField
                                            className='my-2'
                                            error={errorMessage === "" ? false : true}
                                            id="custom-css-outlined-input"
                                            type='number'
                                            color={error ? "error" : ""}
                                            value={score}
                                            inputMode="numeric"
                                            label={errorMessage === '' ? "score" : errorMessage}
                                            onChange={handleChange('score')}
                                            variant="outlined" />
                                        <div className="px-2">Out of 10</div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    <div className="d-flex flex-wrap justify-content-around align-items-center">
                        {count <= 0 ? null :
                            <button
                                onClick={() => {
                                    setCount(count - 1)
                                    setFormDataEdu({ id: education[count - 1]?._id, formCount: count })
                                }}
                                className="btn col-4 rounded-pill btn-danger rounded">
                                Previous
                            </button>}
                        {education.length === 0 ?
                            <button
                                onClick={() => {
                                    setDisplayEducation(false)
                                    setDisplayWork(true)
                                    setDisplayLinks(false)
                                    setDisplayStackOverflow(false)
                                    setCount(0)
                                }}
                                className="btn col-5 rounded-pill btn-success rounded">
                                Next Section
                            </button> : <>
                                {education.length === count + 1 ? <button onClick={() => {
                                    setDisplayEducation(false)
                                    setDisplayWork(true)
                                    setDisplayLinks(false)
                                    setDisplayStackOverflow(false)
                                    handleSubmitIndiScoreEdu({ entityId: education[count]?._id, entityScore: score })
                                    setCount(0)
                                }} className="btn col-5 rounded-pill btn-success rounded">
                                    Next Section
                                </button> :
                                    <button
                                        onClick={() => {
                                            handleSubmitIndiScoreEdu({ entityId: education[count]?._id, entityScore: score })
                                            setCount(count + 1)
                                            setFormDataEduNext({ id: education[count + 1]?._id, formCount: count })
                                        }}
                                        className="btn col-5 rounded-pill btn-success rounded">
                                        Next
                                    </button>}
                            </>}
                    </div>
                </div>
            </div>
        )
    }

    const viewWork = () => {
        return (
            <div className="">
                <div className="fs-2">
                    Work
                </div>
                <div className="border border-primary p-2 rounded">
                    {work.length === 0 ? <div>
                        <div>No Entries</div>
                    </div> :
                        <div>
                            <button
                                onClick={() => {
                                    setDisplayEducation(true)
                                    setDisplayWork(false)
                                    setDisplayLinks(false)
                                    setDisplayStackOverflow(false)
                                    setCount(0)
                                }}
                                className="btn rounded-pill btn-outline-danger rounded px-5">
                                Back to Education
                            </button>
                            <div className="row d-flex justify-content-between align-items-center">
                                <div className="col-md">
                                    <div className="d-flex align-items-end">
                                        <div className="fs-3 capitalize">{count + 1}. {(work[count]?.title)}</div>
                                    </div>
                                    <div className="py-1">
                                        {work[count]?.techUsed.map((t, i) =>
                                            <span key={i} className='bg-primary rounded-pill mx-2 px-2 p-1'>{t}</span>)
                                        }
                                    </div>
                                    <div>
                                        <span className="fs-4">Description:<br /> {work[count]?.description}</span>
                                    </div>
                                    <div>
                                        <span className="fw-bold fs-4">Repo: </span><span className="fs-4"><a href={work[count].repoLink} target="_blank">{work[count].repoName}</a></span>
                                    </div>
                                    <div>
                                        <span className="fs-4">Link: {work[count]?.workLink}</span>
                                    </div>
                                </div>
                                <div className="col-md d-flex justify-content-center align-items-center">
                                    <div className="d-flex align-items-center">
                                        <CssTextField
                                            className='my-2'
                                            error={errorMessage === "" ? false : true}
                                            id="custom-css-outlined-input"
                                            type='number'
                                            color={error ? "error" : ""}
                                            value={score}
                                            inputMode="numeric"
                                            label={errorMessage === '' ? "score" : errorMessage}
                                            onChange={handleChange('score')}
                                            variant="outlined" />
                                        <div className="px-2">Out of 10</div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    <div className="d-flex flex-wrap justify-content-around align-items-center">
                        {count <= 0 ? null :
                            <button
                                onClick={() => {
                                    setCount(count - 1)
                                    setFormDataWork({ id: work[count - 1]?._id, formCount: count })
                                }}
                                className="btn col-4 rounded-pill btn-danger rounded">
                                Previous
                            </button>}
                        {work.length === 0 ?
                            <button
                                onClick={() => {
                                    setDisplayEducation(false)
                                    setDisplayWork(false)
                                    setDisplayLinks(true)
                                    setDisplayStackOverflow(false)
                                    setCount(0)
                                }}
                                className="btn col-5 rounded-pill btn-success rounded">
                                Next Section
                            </button> : <>
                                {work.length === count + 1 ?
                                    <button
                                        onClick={() => {
                                            setDisplayEducation(false)
                                            setDisplayWork(false)
                                            setDisplayLinks(true)
                                            setDisplayStackOverflow(false)
                                            handleSubmitIndiScoreWork({ entityId: work[count]?._id, entityScore: score })
                                            setCount(0)
                                        }}
                                        className="btn col-5 rounded-pill btn-success rounded">
                                        Next Section
                                    </button> :
                                    <button
                                        onClick={() => {
                                            handleSubmitIndiScoreWork({ entityId: work[count]?._id, entityScore: score })
                                            setCount(count + 1)
                                            setFormDataWorkNext({ id: work[count + 1]?._id, formCount: count })
                                        }}
                                        className="btn col-5 rounded-pill btn-success rounded">
                                        Next
                                    </button>}
                            </>}
                    </div>
                </div>
            </div>
        )
    }

    const viewLinks = () => {
        return (
            <div className="">
                <div className="fs-2">
                    Other Links
                </div>
                <div className="border border-primary p-2 rounded">
                    {otherLinks.length === 0 ? <div>
                        <div>No Entries</div>
                    </div> :
                        <div>
                            <button
                                onClick={() => {
                                    setDisplayEducation(false)
                                    setDisplayWork(true)
                                    setDisplayLinks(false)
                                    setDisplayStackOverflow(false)
                                    setCount(0)
                                }}
                                className="btn rounded-pill btn-outline-danger rounded px-5">
                                Back to Work
                            </button>
                            <div className="row d-flex justify-content-between align-items-center">
                                <div className="col-md">
                                    <div className="d-flex align-items-end">
                                        <div className="fs-3">{count + 1}. {otherLinks[count]?.linkTitle}</div>
                                    </div>
                                    <div>
                                        <p className="fs-4">Description: {otherLinks[count]?.linkHref}</p>
                                    </div>
                                </div>
                                <div className="col-md d-flex justify-content-center align-items-center">
                                    <div className="d-flex align-items-center">
                                        <CssTextField
                                            className='my-2'
                                            error={errorMessage === "" ? false : true}
                                            id="custom-css-outlined-input"
                                            type='number'
                                            color={error ? "error" : ""}
                                            value={score}
                                            inputMode="numeric"
                                            label={errorMessage === '' ? "score" : errorMessage}
                                            onChange={handleChange('score')}
                                            variant="outlined" />
                                        <div className="px-2">Out of 10</div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    <div className="d-flex flex-wrap justify-content-around align-items-center">
                        {count <= 0 ? null :
                            <button
                                onClick={() => {
                                    setCount(count - 1)
                                    setFormDataOtherLinks({ id: otherLinks[count - 1]?._id, formCount: count })
                                }}
                                className="btn col-4 rounded-pill btn-danger rounded">
                                Previous
                            </button>}
                        {otherLinks.length === 0 ?
                            <button
                                onClick={() => {
                                    setDisplayEducation(false)
                                    setDisplayWork(false)
                                    setDisplayLinks(false)
                                    setDisplayStackOverflow(true)
                                    setCount(0)
                                }}
                                className="btn col-5 rounded-pill btn-success rounded">
                                Next Section
                            </button> : <>
                                {otherLinks.length === count + 1 ?
                                    <button
                                        onClick={() => {
                                            setDisplayEducation(false)
                                            setDisplayWork(false)
                                            setDisplayLinks(false)
                                            setDisplayStackOverflow(true)
                                            handleSubmitIndiScoreOtherLinks({ entityId: otherLinks[count]?._id, entityScore: score })
                                            setCount(0)
                                        }}
                                        className="btn col-5 rounded-pill btn-success rounded">
                                        Next Section
                                    </button> :
                                    <button
                                        onClick={() => {
                                            handleSubmitIndiScoreOtherLinks({ entityId: otherLinks[count]?._id, entityScore: score })
                                            setCount(count + 1)
                                            setFormDataOtherLinksNext({ id: otherLinks[count + 1]?._id, formCount: count })
                                        }}
                                        className="btn col-4 rounded-pill btn-success rounded">
                                        Next
                                    </button>}
                            </>}
                    </div>
                </div>
            </div>
        )
    }

    const ViewStackOverflow = () => {
        return (
            <div className="">
                <div className="fs-2">
                    StackOverFlow Profile { }
                </div>
                <div className="border border-primary p-2 rounded">
                    <button
                        onClick={() => {
                            setDisplayEducation(false)
                            setDisplayWork(false)
                            setDisplayLinks(true)
                            setDisplayStackOverflow(false)
                            setCount(0)
                        }}
                        className="btn rounded-pill btn-outline-danger rounded px-4">
                        Back to Link
                    </button>
                    <div className="row d-flex justify-content-between align-items-start">
                        <div className="col-md">
                            {stackOverFlowProfile === null || stackOverFlowProfile === '' ? <span>Profile Not Available</span> : <div>
                                <span className="fs-3"><strong>{stackOverFlowProfile.display_name}</strong></span>
                                <div>
                                    <span className="fs-4">Reputation: {stackOverFlowProfile.reputation}</span>
                                </div>
                                <div>
                                    <div>
                                        <span className="fs-4"><strong>Badges</strong></span>
                                    </div>
                                    <div>
                                        <div className="fs-5">Bronze: {stackOverFlowProfile?.badge_counts?.bronze}</div>
                                        <div className="fs-5">Silver: {stackOverFlowProfile?.badge_counts?.silver}</div>
                                        <div className="fs-5">Gold: {stackOverFlowProfile?.badge_counts?.gold}</div>
                                    </div>
                                </div>
                            </div>}
                            {/* {JSON.stringify(stackOverFlowProfile)} */}
                        </div>
                        <div className="col-md d-flex justify-content-center align-items-center">
                            <div className="d-flex align-items-center">
                                <CssTextField
                                    className='my-2'
                                    error={errorMessage === "" ? false : true}
                                    id="custom-css-outlined-input"
                                    type='number'
                                    color={error ? "error" : ""}
                                    value={score}
                                    inputMode="numeric"
                                    label={errorMessage === '' ? "score" : errorMessage}
                                    onChange={handleChange('score')}
                                    variant="outlined" />
                                <div className="px-2">Out of 10</div>
                            </div>
                        </div>
                    </div>
                    <div align="center" className="py-2 d-flex justify-content-around align-items-center">
                        <button
                            onClick={() => {
                                setDisplayEducation(false)
                                setDisplayWork(false)
                                setDisplayLinks(false)
                                setDisplayStackOverflow(false)
                                setDisplayGithub(true)
                                setstackOverflowScore(score)
                                setCount(0)
                            }}
                            className="btn col-5 rounded-pill btn-success rounded">
                            Next Section
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    const viewGithub = () => {
        return (
            <div className="">
                <div className="fs-2">
                    Github Profile
                </div>
                <div className="border border-primary p-2 rounded">
                    <button
                        onClick={() => {
                            setDisplayEducation(false)
                            setDisplayWork(false)
                            setDisplayLinks(false)
                            setDisplayGithub(false)
                            setDisplayStackOverflow(true)
                            setCount(0)
                        }}
                        className="btn rounded-pill btn-outline-danger rounded px-4">
                        Back to StackOverFlow
                    </button>
                    <div className="row d-flex justify-content-between align-items-start">
                        <div className="col-md">
                            {/* {JSON.stringify(githubProfile)} */}
                            <div>
                                <div>
                                    <span className="fs-3"><strong>{githubProfile?.name}</strong> ({githubProfile?.login})</span>
                                </div>
                                <div className="fs-5">
                                    <div>Public Repositories: {githubProfile?.public_repos}</div>
                                    <div>Followers: {githubProfile?.followers}</div>
                                    <div>Following: {githubProfile?.following}</div>
                                </div>
                                <div className="py-2">
                                    <button onClick={() => { displayRepos ? setDisplayRepos(false) : setDisplayRepos(true) }}
                                        className="btn btn-outline-dark rounded-pill">View Repos</button>
                                    <Collapse in={displayRepos}>
                                        <div style={{ height: "20vh", overflowY: 'scroll' }} className="p-2 my-2 card bg-transparent border-primary rounded-lg">
                                            {githubRepos?.map((r, i) => (
                                                <div key={i}>
                                                    <div>Repo Name: <a target="_blank" href={`https://github.com/${githubProfile?.login}/${r.name}`}>{r.name}</a></div>
                                                    <div>Languages: {r.language}</div>
                                                    <hr />
                                                </div>
                                            ))}
                                        </div>
                                    </Collapse>
                                </div>
                            </div>
                        </div>
                        <div className="col-md d-flex justify-content-center align-items-center">
                            <div className="d-flex align-items-center">
                                <CssTextField
                                    className='my-2'
                                    error={errorMessage === "" ? false : true}
                                    id="custom-css-outlined-input"
                                    type='number'
                                    color={error ? "error" : ""}
                                    value={score}
                                    inputMode="numeric"
                                    label={errorMessage === '' ? "score" : errorMessage}
                                    onChange={handleChange('score')}
                                    variant="outlined" />
                                <div className="px-2">Out of 10</div>
                            </div>
                        </div>
                    </div>
                    <div align="center" className="py-2 d-flex justify-content-around align-items-center">
                        <button
                            onClick={() => {
                                setDisplayEducation(false)
                                setDisplayWork(false)
                                setDisplayLinks(false)
                                setDisplayStackOverflow(false)
                                setDisplayGithub(false)
                                setGithubScore(score)
                                setDisplayReviewForm(true)
                                setCount(0)
                            }}
                            className="btn col-5 rounded-pill btn-success rounded">
                            Next Section
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    const viewOverview = () => {
        return (
            <div>
                <div className="fs-2">
                    View Overview
                </div>
                <div className="border border-primary p-2 rounded">
                    <div className="row d-flex justify-content-between align-items-center">
                        <div className="col-md">
                            <div className="d-flex justify-content-start align-items-top">
                                <div className="fs-3 col-4">
                                    Education Scores
                                </div>
                                <div className="px-3 border-start col-8">
                                    {scoreObjectEdu.map((e, i) => (
                                        <div key={i} className="">
                                            {e.entityTitle} {e.entityScore}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <hr />
                            <div className="d-flex justify-content-start align-items-top">
                                <div className="fs-3 col-4">
                                    Work Scores
                                </div>
                                <div className="px-3 border-start col-8">
                                    {scoreObjectWork.map((e, i) => (
                                        <div key={i} className="">
                                            {e.entityTitle} {e.entityScore}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <hr />
                            <div className="d-flex justify-content-start align-items-top">
                                <div className="fs-3 col-4">
                                    Others
                                </div>
                                <div className="px-3 border-start col-8">
                                    {scoreObjectOtherLinks === undefined ? <div>
                                        No Links
                                    </div> : <div>
                                        {scoreObjectOtherLinks.map((e, i) => (
                                            <div key={i} className="">
                                                {e.entityTitle} {e.entityScore}
                                            </div>
                                        ))}</div>}
                                </div>
                            </div>
                            <hr />
                            <div className="d-flex justify-content-start align-items-center">
                                <div className="fs-4 text-wrap col-4" style={{ wordWrap: 'break-word' }}>
                                    StackOverFlow Score
                                </div>
                                <div className="px-3 border-start col-8">
                                    {stackOverflowScore}
                                </div>
                            </div>
                            <hr />
                            <div className="d-flex justify-content-start align-items-center">
                                <div className="fs-4 col-4">
                                    Github Score
                                </div>
                                <div className="px-3 border-start col-8">
                                    {githubScore}
                                </div>
                            </div>
                            <hr />
                            <div className="d-flex justify-content-start align-items-center">
                                <div className="fs-4 col-4">
                                    Your views
                                </div>
                                <div className="px-3 border-start col-8">
                                    {review}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div align="center" className="py-2 d-flex justify-content-around align-items-center">
                        <button
                            onClick={() => {
                                setDisplayEducation(false)
                                setDisplayWork(false)
                                setDisplayLinks(false)
                                setDisplayStackOverflow(false)
                                setDisplayGithub(false)
                                setDisplayReviewForm(true)
                                setDisplayOverview(false)
                                setGithubScore(score)
                                setCount(0)
                            }}
                            className="btn col-4 rounded-pill btn-outline-danger rounded">
                            Back to Review
                        </button>
                        <button
                            onClick={() => {
                                handleScoreSubmit()
                            }}
                            className="btn col-4 rounded-pill btn-success rounded">
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    const viewReviewForm = () => {
        return (
            <div className="">
                <div className="fs-2">
                    Your Views
                </div>
                <div className="border border-primary p-2 rounded">
                    <div className="row d-flex justify-content-between align-items-center">
                        <div className="d-flex justify-content-center">
                            <CssTextField
                                className='my-2 col-10'
                                error={reviewErrorMessage === "" ? false : true}
                                id="custom-css-outlined-input"
                                multiline
                                rows={4}
                                color={reviewError ? "error" : ""}
                                value={review}
                                label={reviewErrorMessage === '' ? "Your Reviews" : reviewErrorMessage}
                                onChange={handleReviewChange('review')}
                                variant="outlined" />
                        </div>
                    </div>
                    <div align="center" className="py-2 d-flex justify-content-around align-items-center">
                        <button
                            onClick={() => {
                                setDisplayEducation(false)
                                setDisplayWork(false)
                                setDisplayLinks(false)
                                setDisplayGithub(true)
                                setDisplayStackOverflow(false)
                                setDisplayReviewForm(false)
                                setCount(0)
                            }}
                            className="btn col-4 rounded-pill btn-outline-danger rounded">
                            Back to GitHub
                        </button>
                        <button
                            onClick={() => {
                                setDisplayEducation(false)
                                setDisplayWork(false)
                                setDisplayLinks(false)
                                setDisplayStackOverflow(false)
                                setDisplayGithub(false)
                                setDisplayReviewForm(false)
                                setDisplayOverview(true)
                                setGithubScore(score)
                                setCount(0)
                            }}
                            className="btn col-4 rounded-pill btn-success rounded">
                            Next Section
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div>
            <div>
                <Collapse in={displayEducation}>
                    {viewEducation()}
                </Collapse>
                <div>
                </div>
            </div>
            <div>
                <Collapse in={displayWork}>
                    {viewWork()}
                </Collapse>
            </div>
            <div>
                <Collapse in={displayLinks}>
                    {viewLinks()}
                </Collapse>
            </div>
            <div>
                <Collapse in={displayStackOverflow}>
                    {ViewStackOverflow()}
                </Collapse>
            </div>
            <div>
                <Collapse in={displayGithub}>
                    {viewGithub()}
                </Collapse>
            </div>
            <div>
                <Collapse in={displayOverview}>
                    {viewOverview()}
                </Collapse>
            </div>
            <div>
                <Collapse in={displayReviewForm}>
                    {viewReviewForm()}
                </Collapse>
            </div>
        </div>
    )
}

export default SingleUserReview