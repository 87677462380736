import { useState, useEffect } from "react";
import { Link, Outlet } from "react-router-dom";
import { AllowAdvancedAccount } from "../../manageRoutes/ProtectedRoutes";
import AuthNavbar from "../AuthNavbar";
import { useAuthStore } from "../../store/store";
import { updateAdvAccount } from "../../actions/advAccActions";
import { Collapse, TextField } from "@mui/material";
import { deleteProject, getUserProject } from "../../actions/projectActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";

const AdvAccountDashboard = () => {

    const user = useAuthStore.getState().user
    const setStateUser = useAuthStore(state => state.setUser)

    const [formData, setFormData] = useState({
        userId: user.user._id,
        representingCompany: '',
        positionInCompany: '',
        companyDescription: '',
        companyEmail: '',
        companyContactNo: '',
        errorType: '',
        errorMessage: ''
    })

    const [addedProjects, setAddedProjects] = useState([])
    const [showForm, setShowForm] = useState(false)
    const [refresh, setRefresh] = useState(false)

    const { userId, companyContactNo, companyDescription, companyEmail, representingCompany, positionInCompany, errorMessage, errorType } = formData


    const handleChange = name => event => {
        setFormData({ ...formData, [name]: event.target.value, errorMessage: '', errorType: '' })
    }


    useEffect(() => {
        getProject()
    }, [refresh])

    const getProject = () => {
        getUserProject(user?.user?._id).then(res => {
            setAddedProjects(res.reverse())
        }).catch(err => {
            console.log(err)
        })
    }

    const [showReqs, setShowReqs] = useState(-1)


    const handleUpdateProfile = () => {
        if (representingCompany === '') {
            return setFormData({ ...formData, errorType: 'representingCompany', errorMessage: 'Required' })
        }
        if (companyDescription === '') {
            return setFormData({ ...formData, errorType: 'companyDescription', errorMessage: 'Required' })
        }
        if (companyEmail === '') {
            return setFormData({ ...formData, errorType: 'companyEmail', errorMessage: 'Required' })
        }
        if (positionInCompany === '') {
            return setFormData({ ...formData, errorType: 'positionInCompany', errorMessage: 'Required' })
        }
        if (companyContactNo === '') {
            return setFormData({ ...formData, errorType: 'companyContactNo', errorMessage: 'Required' })
        }
        if (companyEmail === '') {
            return setFormData({ ...formData, errorType: 'companyEmail', errorMessage: 'Required' })
        } else {
            updateAdvAccount(formData).then(res => {
                // console.log(res)
                if (res.success) {
                    // var storedDataString = localStorage.getItem('user');
                    let userData = JSON.parse(localStorage.getItem('user'));
                    userData.profile = res.response
                    localStorage.setItem('user', JSON.stringify(userData));
                    setStateUser(userData)
                    setShowForm(false)
                    setRefresh(true)
                }
            }).catch(err => {
                console.log(err)
            })
        }

        setRefresh(false)
    }

    const handelDeleteProject = (projectId) => {
        deleteProject(projectId).then(res => {
            if (res) {
                setRefresh(true)
            }
        }).catch(err => {
            console.log(err)
        })
        setRefresh(false)
    }

    return (
        <AuthNavbar>
            <AllowAdvancedAccount>
                <div className="container-fluid py-5">
                    {/* <div align='center' className="h3 text-danger">
                        This section is not stable yet please wait for beta release phase 2.
                    </div>
                    <div align='center' className="h3 text-danger">
                        If you wish to exprience you can move further
                    </div> */}
                    <div className="d-flex flex-wrap justify-content-center align-items-start">
                        <div className="card border-0 shadow p-2 bg-transparent col-md-3 p-3 col-12" style={{
                            position: "sticky", top: 0
                        }}>
                            <Collapse in={!showForm}>
                                {user.profileStatus === false ?
                                    <div className="card bg-danger my-2 text-white text-center p-2">
                                        <h2 className="">Please Complete Your Profile</h2>
                                        <button onClick={() => setShowForm(true)} className="btn btn-warning btn-sm col-12">Update your profile</button>
                                    </div> : null
                                }
                            </Collapse>
                            <div className="card p-2 bg-transparent my-2 border-primary">
                                <span className="fw-bold fs-4">{user.user.firstName} {user.user.middleName} {user.user.lastName}</span>
                                <span className="fw-bold fs-5">{user.user.email}</span>
                                <hr />
                                <div>
                                    <div className="">
                                        <span className="fw-bold fs-5">Company Representing: </span>
                                        <span className="fs-5">{user?.profile?.representingCompany}</span>
                                    </div>
                                    <div className="">
                                        <span className="fw-bold fs-5">Company Desciption: </span>
                                        <span className="fs-5">{user?.profile?.companyDescription}</span>
                                    </div>
                                    <div className="">
                                        <span className="fw-bold fs-5">Position in Company: </span>
                                        <span className="fs-5">{user?.profile?.positionInCompany}</span>
                                    </div>
                                    <div className="">
                                        <span className="fw-bold fs-5">Company Email: </span>
                                        <span className="fs-5">{user?.profile?.companyEmail}</span>
                                    </div>
                                    <div className="">
                                        <span className="fw-bold fs-5">Company Contact: </span>
                                        <span className="fs-5">{user?.profile?.companyContactNo}</span>
                                    </div>
                                </div>
                            </div>
                            {showForm ? null :
                                <div>
                                    <button onClick={() => {
                                        setShowForm(true)
                                        setFormData({
                                            ...formData,
                                            representingCompany: user.profile.representingCompany,
                                            companyDescription: user.profile.companyDescription,
                                            positionInCompany: user.profile.positionInCompany,
                                            companyEmail: user.profile.companyEmail,
                                            companyContactNo: user.profile.companyContactNo
                                        })
                                    }} className="btn btn-warning btn-sm col-12">Update your profile</button>
                                </div>}
                            <Collapse in={showForm}>
                                <div className="py-2">
                                    <div className="py-2">
                                        <button onClick={() => setShowForm(false)} className="btn btn-sm btn-danger">
                                            Back
                                        </button>
                                    </div>
                                    <div>
                                        <span className="fw-bold fs-5">Required Details</span>
                                    </div>
                                    <div className="col-12">
                                        <div className=''>
                                            <TextField
                                                style={{ width: '100%' }}
                                                className='my-2'
                                                error={errorType === "representingCompany" ? true : false}
                                                id="outlined-basic"
                                                value={representingCompany}
                                                label={errorType === 'representingCompany' ? `Company Representing ${errorMessage}` : "Company Representing"}
                                                onChange={handleChange('representingCompany')}
                                                variant="outlined" />
                                        </div>
                                        <div className=''>
                                            <TextField
                                                style={{ width: '100%' }}
                                                className='my-2'
                                                error={errorType === "companyDescription" ? true : false}
                                                id="outlined-basic"
                                                value={companyDescription}
                                                label={errorType === 'companyDescription' ? `Company Description ${errorMessage}` : "Company Description"}
                                                onChange={handleChange('companyDescription')}
                                                variant="outlined" />
                                        </div>
                                        <div className=''>
                                            <TextField
                                                style={{ width: '100%' }}
                                                className='my-2'
                                                error={errorType === "positionInCompany" ? true : false}
                                                id="outlined-basic"
                                                value={positionInCompany}
                                                label={errorType === 'positionInCompany' ? `Position In Company ${errorMessage}` : "Your Position in Company"}
                                                onChange={handleChange('positionInCompany')}
                                                variant="outlined" />
                                        </div>
                                        <div className=''>
                                            <TextField
                                                style={{ width: '100%' }}
                                                className='my-2'
                                                error={errorType === "companyEmail" ? true : false}
                                                id="outlined-basic"
                                                value={companyEmail}
                                                label={errorType === 'companyEmail' ? `Company Email ${errorMessage}` : "Official Email Company"}
                                                onChange={handleChange('companyEmail')}
                                                variant="outlined" />
                                        </div>
                                        <div className=''>
                                            <TextField
                                                style={{ width: '100%' }}
                                                className='my-2'
                                                error={errorType === "companyContactNo" ? true : false}
                                                id="outlined-basic"
                                                value={companyContactNo}
                                                label={errorType === 'companyContactNo' ? `Company Contact No ${errorMessage}` : "Official Company Contact No"}
                                                onChange={handleChange('companyContactNo')}
                                                variant="outlined" />
                                        </div>
                                    </div>
                                    <div className="py-2">
                                        <button onClick={() => handleUpdateProfile()} className="btn fw-bold btn-primary col-12">
                                            Update
                                        </button>
                                    </div>
                                </div>
                            </Collapse>
                        </div>
                        <div className="col-md-8 p-2 col-12" style={{
                            height: '90vh',
                            overflowY: 'auto'
                        }}>
                            <div>
                                {/* <Link to="/advanced-dashboard/manage-projects">
                                    <button className="btn rounded-pill btn-outline-primary">
                                        View projects
                                    </button>
                                </Link> */}
                                {/* <Link to="/advanced-dashboard/display-results">
                                    <button className="btn rounded-pill btn-outline-primary">
                                        Display Results
                                    </button>
                                </Link> */}

                                <div className="d-flex justify-content-center">
                                    <div className="col-md-9 col-12">
                                        <div className="d-flex flex-wrap justify-content-between align-items-center">
                                            <div>
                                                <h2 className="m-0">Added Projects</h2>
                                            </div>
                                            <div>
                                                <Link to={`/advanced-dashboard/manage-projects`}>
                                                    <button className="btn btn-success fw-bold rounded-pill">
                                                        Add New Project
                                                    </button>
                                                </Link>
                                            </div>
                                        </div>
                                        <hr />
                                        {addedProjects.map((a, i) => (
                                            <div key={i} className="card border-0 shadow bg-transparent my-5 p-2">
                                                <div className="d-flex col-12 justify-content-between align-items-center">
                                                    <div>
                                                        <Link to={`manage-projects/single-project/${a._id}`} className="" state={a._id}><h3 className="fw-bold">{a.projectTitle}
                                                            {a.isDeleted ? <span> (Deleted)</span> : null}</h3></Link>
                                                        <p className={a?.isPrivate ? `text-danger fw-bold` : `text-success fw-bold`}>Visibility: {a?.isPrivate ? 'Public' : 'Private'}</p>
                                                    </div>
                                                    <div className="col-5 border-start border-3 d-flex justify-content-around align-items-center">
                                                        <Link to={`/advanced-dashboard/manage-projects`} state={{
                                                            update: true,
                                                            projectIdFromDash: a.projectId,
                                                            projectTitleFromDash: a.projectTitle,
                                                            projectDescriptionFromDash: a.projectDescription,
                                                            projectCurrentReqFromDash: a.projectCurrentReq,
                                                            projectTechFromDash: a.projectTech,
                                                            isPrivateFromDash: a.isPrivate
                                                        }}>
                                                            <button className={a.isDeleted ? "btn disabled btn-warning rounded-pill" : "btn btn-warning rounded-pill"}>
                                                                <span className="d-md-inline fw-bold d-none">Update</span> <FontAwesomeIcon icon={faEdit} />
                                                            </button>
                                                        </Link>
                                                        <button onClick={() => handelDeleteProject(a._id)}
                                                            className={a.isDeleted ? "btn disabled btn-danger rounded-pill" : "btn btn-danger rounded-pill"}>
                                                            <span className="d-md-inline fw-bold d-none">Delete</span> <FontAwesomeIcon icon={faTrash} />
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="col-12 d-flex flex-wrap ">
                                                    {a?.projectTech.map((p, i) => (
                                                        <div key={i} className="bg-primary rounded-pill px-2 me-2">
                                                            <span className="fs-6 text-white fw-bold">{p}</span>
                                                        </div>
                                                    ))}
                                                </div>
                                                <p className="m-0 fs-5">{a?.projectDescription}</p>
                                                <hr />
                                                <div className="">
                                                    <div className="d-flex flex-wrap justify-content-between align-items-center">
                                                        <div>
                                                            <h4>Project Current Requirements</h4>
                                                        </div>
                                                        <div>
                                                            {showReqs === i ?
                                                                <button className="btn btn-danger rounded-pill fw-bold" onClick={() => setShowReqs(-1)}>
                                                                    Close
                                                                </button> : <>
                                                                    {a?.projectCurrentReq.length === 0 ? <div className="text-center">
                                                                        <span className="fw-bold fs-5 text-danger">No requirements Added</span>
                                                                    </div> :
                                                                        <button className="btn btn-info rounded-pill fw-bold" onClick={() => setShowReqs(i)}>
                                                                            Show Requirements added
                                                                        </button>}
                                                                </>
                                                            }
                                                        </div>
                                                    </div>
                                                    <Collapse in={showReqs === i}>
                                                        {a?.projectCurrentReq.length !== 0 ? <div>{a?.projectCurrentReq.map((p, i) => (
                                                            <div key={i}>
                                                                <div className="d-flex align-items-start justify-content-start">
                                                                    <span className="fs-5 col-1 text-center">{i + 1}.</span>
                                                                    <div className="col-9">
                                                                        <p className="m-0 fs-5">{p?.title}</p>
                                                                        <p className="m-0 fs-5">{p?.description}</p>
                                                                    </div>
                                                                </div>
                                                                <hr className="mx-2 mx-md-5" />
                                                            </div>
                                                        ))}</div> : <div className="text-center">
                                                            <span className="fw-bold fs-5 text-danger">No requirements Added</span>
                                                        </div>}
                                                    </Collapse>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <Outlet /> */}
            </AllowAdvancedAccount>
        </AuthNavbar>
    )
}

export default AdvAccountDashboard