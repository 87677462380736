import {apiUrl} from './../constants'

export const submitProfile = (profileData) => {
    return fetch(`${apiUrl}/create-profile`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(profileData)
    }).then(response => {
        return response.json()
    }).catch(err => {
        console.log("PROFILE-CREATE-ERR: ", err)
    })
}

export const getProfiles = () => {
    return fetch(`${apiUrl}/get-profiles`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(response => {
        return response.json()
    }).catch(err => {
        return err.json()
    })
}

export const getUserProfile = (userId) => {
    return fetch(`${apiUrl}/get-user-profile/${userId}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(response => {
        return response.json()
    }).catch(err => {
        return err.json()
    })
} 

export const submitReviewerProfile = (profileData) => {
    return fetch(`${apiUrl}/create-reviewer-profile`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(profileData)
    }).then(response => {
        return response.json()
    }).catch(err => {
        console.log("PROFILE-CREATE-ERR: ", err)
    })
}