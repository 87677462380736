import { useLocation, useNavigate, Link, Outlet, useParams } from "react-router-dom"
import AuthNavbar from "../AuthNavbar"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useState } from "react"
import { getProfiles, submitProfile } from "../../actions/userProfileActions"
import { Collapse } from "@mui/material"
import TextField from '@mui/material/TextField';
import { styled } from '@mui/system';
import { deSelectRequestedMember, fetchUsers, joinTeamRequest, getSingleProjectForTeam, submitCoHost, getUsersByTechnologies } from "../../actions/projectActions"
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { AllowAdvancedAccount } from "../../manageRoutes/ProtectedRoutes"

const CssTextField = styled(TextField)({
    '& label': {
        color: "#0275d8"
    },
    '& input': {
        color: "black"
    },
    '& .MuiInputBase-multiline': {
        color: "black"
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#0275d8',
        color: "black"
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#0275d8',
        },
        '&:hover fieldset': {
            borderColor: '#0275d8',
        },
    },
    '& .MuiChip-label': {
        color: 'black'
    },
    '& .MuiSvgIcon-root .MuiSvgIcon-fontSizeMedium .MuiChip-deleteIcon .MuiChip-deleteIconMedium .MuiChip-deleteIconColorDefault .MuiChip-deleteIconOutlinedColorDefault': {
        borderColor: 'white'
    },
    '& .MuiAutocomplete-clearIndicator': {
        color: "black"
    }
});

const StyledSelect = styled(Select)(({ theme }) => ({
    color: 'black',
    label: 'black',
    icon: 'black',
    '& label': {
        color: '#0275d8',
    },
    '& .MuiSvgIcon-root': {
        color: 'black',
    },
}));


const ManageTeam = () => {
    const navigate = useNavigate()

    const project_id = useParams().pId

    const [refresh, setRefresh] = useState(false)

    useEffect(() => {
        // getProfiles().then(res => {
        //     setProfiles(res)
        // }).catch(err => {
        //     console.log(err)
        // })
        getSingleProjectForTeam(project_id).then(res => {
            setProjectData(res)
            getUsersByTechnologies(res.projectTech).then(res => {
                setRecomProfile(res)
            }).catch(err => {
                console.log("tech fetch user error", err)
            })
        }).catch(err => {
            console.log(err)
        })
    }, [refresh])

    const [projectData, setProjectData] = useState({})
    const [showTeam, setShowTeam] = useState(true)
    const [searchUsers, setSearchUsers] = useState(false)
    const [showSelectedUsers, setShowSelectedUsers] = useState(false)
    const [showUpdateCoHost, setShowUpdateCoHost] = useState(false)
    const [displayOutlet, setDisplayOutlet] = useState(false)
    const [fetchedUser, setFetchedUser] = useState([])
    const [teamCollapsible, setTeamCollapsible] = useState(-1)
    const [showCollapsible, setShowCollapsible] = useState(-1);
    const [showSelectedCollapsible, setShowSelectedCollapsible] = useState(-1);
    const [selectProjectCoHost, setSelectProjectCoHost] = useState('')
    const [recomProfile, setRecomProfile] = useState([])

    const [values, setValues] = useState({
        text: '',
        error: false,
        errorMessage: ''
    })

    const { text, error, errorMessage } = values

    const handleChange = name => event => {
        setValues({ ...values, error: false, errorMessage: '', [name]: event.target.value })
        if (event.target.value.length <= 2) {
            setFetchedUser([])
        } else {
            fetchUsers(event.target.value).then(res => {
                setFetchedUser(res)
            }).catch(err => {
                console.log(err)
            })
        }
    }
    useEffect(() => {
        if (text.length >= 2) {
            fetchUsers(text).then(res => {
                setFetchedUser(res)
            }).catch(err => {
                console.log(err)
            })
        }
    }, [text.length <= 2])

    const handleDeSelectRequestedMember = (projectId, selectedId) => {
        deSelectRequestedMember({ projectId, selectedId }).then(res => {
            if (res) {
                // navigate('/advanced-dashboard/manage-projects', {state:{status: 'negative', success : "User Deselected successfully"}})
                setRefresh(true)
            }
        }).catch(err => {
            console.log(err)
        })
        setRefresh(false)
    }

    const handlePostSearch = (text) => {
        if (text.length >= 2) {
            fetchUsers(text).then(res => {
                setFetchedUser(res)
            }).catch(err => {
                console.log(err)
            })
        }
    }

    const [sudoRequest, setSudoRequest] = useState([])
    const [sudoUserRequestIds, setSudoUserRequestIds] = useState([])

    const handleAddTeamRequest = (userId) => {
        setSudoRequest(sudoRequest => [...sudoRequest, userId])
        setSudoUserRequestIds(sudoUserRequestIds => [...sudoUserRequestIds, userId.userId._id])
    }

    const onSubmitRequests = (projectId, selectedIds) => {
        joinTeamRequest({ projectId, selectedIds }).then(res => {
            if (res) {
                navigate('/advanced-dashboard/manage-projects', { state: { status: 'positive', success: "Request send succesfully" } })
                setRefresh(true)
            }
        }).catch(err => {
            console.log(err)
        })
        setRefresh(false)
    }

    const handleRemoveTeamRequest = (userId) => {
        setSudoRequest(sudoRequest.filter(e => JSON.stringify(e) !== JSON.stringify(userId)))
        setSudoUserRequestIds(sudoUserRequestIds.filter(e => JSON.stringify(e) !== JSON.stringify(userId.userId._id)))
    }

    const handleChangeCoHost = (event) => {
        setSelectProjectCoHost(event.target.value)
    }

    const showRequestButton = ({ projectData, profile }) => {
        let i
        for (i = 0; i <= projectData.projectTeam.length; i++) {
            if (profile.userId._id === projectData.projectTeam[i]) {
                return (<div>Already Added</div>)
            }
        }
        for (i = 0; i <= projectData.projectTeamRequested.length; i++) {
            if (profile.userId._id === projectData.projectTeamRequested[i]?._id) {
                return (<div>User Requested</div>)
            }
        }
        for (i = 0; i <= sudoRequest.length; i++) {
            if (profile.userId._id === sudoRequest[i]?.userId._id) {
                return (<button onClick={() => handleRemoveTeamRequest(profile)} className="btn fw-bold btn-danger rounded-pill">
                    Deselect
                </button>)
            }
        }
        for (i = 0; i <= sudoRequest.length; i++) {
            if (profile.userId._id !== sudoRequest[i]?.userId._id) {
                return (<button onClick={() => {
                    handlePostSearch(text)
                    setSearchUsers(true)
                    setShowSelectedUsers(true)
                    handleAddTeamRequest(profile)
                }} className="btn btn-success fw-bold rounded-pill">
                    Select
                </button>)
            }
        }
    }

    const handleSubmitCoHost = ({ projectId, userId }) => {
        submitCoHost({ projectId, userId }).then(res => {
            if (res) {
                setRefresh(true)
                setShowUpdateCoHost(false)
            }
        }).catch(err => {
            console.log(err)
        })
        setRefresh(false)
    }

    return (
        <AuthNavbar>
            <AllowAdvancedAccount>
                <div className=" px-md-5 px-2 py-5">
                    <Link to={`/advanced-dashboard/manage-projects/single-project/${projectData._id}`} className="" style={{ textDecoration: 'none' }}>
                        <button className="btn btn-warning col-md-1 fw-bold rounded-pill">
                            <FontAwesomeIcon size="1x" icon={faArrowLeft} /><span className="ps-lg-3">back</span>
                        </button>
                    </Link>
                </div>
                <div className="container-fluid d-flex align-items-start m-auto row pb-5">
                    <div className={"col-lg-6 order-lg-1 order-2"}>
                        <div>
                            <h3 className="fw-bold">Fetch your perfect team here</h3>
                        </div>
                        <div>
                            <div className="col-12 my-2">
                                <CssTextField
                                    className=' col-12'
                                    error={errorMessage === "" ? false : true}
                                    id="custom-css-outlined-input"
                                    color={error ? "error" : ""}
                                    value={text}
                                    autoComplete='off'
                                    label={errorMessage === '' ? "Enter single tech to search user" : errorMessage}
                                    onChange={handleChange('text')}
                                    variant="outlined" />
                                <label className="text-danger fs-6">*Type single tech to fetch users</label>
                            </div>
                        </div>
                        <div className="">
                            {fetchedUser.length === 0 ? <div className="conatiner-fluid card p-2 bg-transparent " style={{ height: "50vh", overflowY: "scroll" }}>
                                <div className="pt-2 fw-bold">
                                    <h3>Recommended by devSemble: </h3>
                                </div>
                                <hr className="mt-0" />
                                {recomProfile.map((f, i) => (
                                    <div key={i} style={{
                                        border: 'solid #0257d8',
                                        borderWidth: 'thin'
                                    }} className="bg-light p-2 my-1 rounded">
                                        <div className="d-flex flex-wrap justify-content-between align-items-center ">
                                            <div className="col-12 col-sm-7 d-flex flex-wrap justify-content-between align-items-center ">
                                                <div className="col-6 fs-5 fw-bold ">{f?.userId.firstName} {f?.userId.lastName}</div>
                                                <div className="col-6 fw-bold">
                                                    {f?.rankId?.rankRange === null ? 'Not Ranked' : <>{f?.rankId?.rankRange}</>}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-5 d-flex justify-content-between align-items-center">
                                                {showRequestButton({ projectData: projectData, profile: f })}
                                                {showCollapsible === i ?
                                                    <button onClick={() => setShowCollapsible(-1)} className="btn btn-danger fw-bold rounded-pill">
                                                        Close
                                                    </button> :
                                                    <button onClick={() => setShowCollapsible(i)} className="btn btn-primary fw-bold rounded-pill">
                                                        View Details
                                                    </button>}
                                            </div>
                                        </div>
                                        <div className="">
                                            <Collapse className="" in={showCollapsible === i}>
                                                <div className="py-3 d-flex flex-wrap justify-content-around align-items-start">
                                                    <div className="card col-md-5 col-12 m-1 p-2 bg-transparent border-secondary rounded-lg">
                                                        <div>
                                                            <span className="fs-4"><b>Work Exprience</b></span>
                                                            <div>
                                                                {f?.workExprience.map((w, i) => (
                                                                    <div key={i} className="">
                                                                        <span className="fs-4">{w.title}</span>
                                                                        <div className="d-flex flex-wrap ">
                                                                            {w?.techUsed.map((t, i) => (
                                                                                <div key={i} className="bg-primary rounded-pill px-2 me-2 text-white fw-bold">
                                                                                    <span className="">{t}</span>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                        <hr className="mb-2" />
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card col-md-5 col-12 m-1 p-2 bg-transparent border-secondary rounded-lg">
                                                        <div>
                                                            <span className="fs-4"><b>Education Exprience</b></span>
                                                            <div>
                                                                {f?.educationExprience.map((e, i) => (
                                                                    <div key={i} className="">
                                                                        <span className="fs-4">{e.eduTitle}</span>
                                                                        <hr className="mb-2" />
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Collapse>
                                        </div>
                                        {/* <hr /> */}
                                    </div>
                                ))}
                            </div> : <div className="conatiner-fluid card p-2 bg-transparent " style={{ height: "50vh", overflowY: "scroll" }}>
                                {fetchedUser.map((f, i) => (
                                    <div key={i} style={{
                                        border: 'solid #0257d8',
                                        borderWidth: 'thin'
                                    }} className="bg-light p-2 my-1 rounded">
                                        <div className="d-flex flex-wrap justify-content-between align-items-center ">
                                            <div className="col-12 col-sm-7 d-flex flex-wrap justify-content-between align-items-center ">
                                                <div className="col-6 fs-5 fw-bold ">{f?.userId.firstName} {f?.userId.lastName}</div>
                                                <div className="col-6 fw-bold">
                                                    {!f?.rankId?.rankRange === null ? 'Not Ranked' : <>{f?.rankId?.rankRange}</>}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-5 d-flex justify-content-between align-items-center">
                                                {showRequestButton({ projectData: projectData, profile: f })}
                                                {showCollapsible === i ?
                                                    <button onClick={() => setShowCollapsible(-1)} className="btn fw-bold btn-danger rounded-pill">
                                                        Close
                                                    </button> :
                                                    <button onClick={() => setShowCollapsible(i)} className="btn fw-bold btn-primary rounded-pill">
                                                        View Details
                                                    </button>}
                                            </div>
                                        </div>
                                        <div className="">
                                            <Collapse className="" in={showCollapsible === i}>
                                                <div className="py-3 d-flex flex-wrap justify-content-around align-items-start">
                                                    <div className="card col-md-5 col-12 m-1 p-2 bg-transparent border-secondary rounded-lg">
                                                        <div>
                                                            <span className="fs-4"><b>Work Exprience</b></span>
                                                            <div>
                                                                {f?.workExprience.map((w, i) => (
                                                                    <div key={i} className="">
                                                                        <span className="fs-4">{w.title}</span>
                                                                        <div className="d-flex flex-wrap ">
                                                                            {w?.techUsed.map((t, i) => (
                                                                                <div key={i} className="bg-primary rounded-pill px-2 me-2 text-white fw-bold">
                                                                                    <span className="">{t}</span>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                        <hr className="mb-2" />
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card col-md-5 col-12 m-1 p-2 bg-transparent border-secondary rounded-lg">
                                                        <div>
                                                            <span className="fs-4"><b>Education Exprience</b></span>
                                                            <div>
                                                                {f?.educationExprience.map((e, i) => (
                                                                    <div key={i} className="">
                                                                        <span className="fs-4">{e.eduTitle}</span>
                                                                        <hr className="mb-2" />
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Collapse>
                                        </div>
                                    </div>
                                ))}
                            </div>}
                        </div>
                    </div>
                    <div className="col-lg-6 order-lg-2 order-2">
                        <Collapse in={!searchUsers || sudoRequest.length === 0}>
                            <div className="d-flex flex-wrap justify-content-center align-items-center">
                                <div className="col-12 pb-3 d-flex row justify-content-around align-items-start">
                                    <div>
                                        {projectData.projectCoHost === undefined ? <div className="d-flex flex-wrap justify-content-between align-items-center">
                                            {projectData?.projectTeam?.length >= 2 ? <>
                                                <span className="fs-4 fw-bold">Select Co-Host</span>
                                                <div className="col-6">
                                                    <StyledSelect
                                                        className="col-12"
                                                        labelId="my-select-label"
                                                        id="demo-simple-select"
                                                        value={selectProjectCoHost}
                                                        label="Select Co-Host"
                                                        variant="outlined"
                                                        onChange={handleChangeCoHost}
                                                    >
                                                        {projectData.projectTeam?.map((p, i) => (
                                                            <MenuItem key={i} value={p._id}><span className="fs-5">{p.firstName} {p.lastName}</span></MenuItem>
                                                        ))}
                                                    </StyledSelect>
                                                </div>
                                                <button
                                                    onClick={() => handleSubmitCoHost({ projectId: projectData._id, userId: selectProjectCoHost })}
                                                    className="btn btn-primary rounded-pill">Add as Co-Host</button>
                                            </> : <span className="text-danger fw-bold">*For Co-host Need atleast 2 team members</span>}
                                        </div> : <div className="d-flex flex-wrap justify-content-between align-items-center">
                                            <span className="fs-3 fw-bold">Project Co Host: {projectData.projectCoHost?.firstName} {projectData.projectCoHost?.lastName}</span>
                                            {showUpdateCoHost ?
                                                <button onClick={() => setShowUpdateCoHost(false)} className="btn btn-danger rounded-pill">Close</button> :
                                                <button onClick={() => setShowUpdateCoHost(true)} className="btn fw-bold btn-info rounded-pill">Update Co-host</button>
                                            }
                                        </div>}
                                        <Collapse in={showUpdateCoHost}>
                                            <div className="d-flex flex-wrap justify-content-between align-items-center">
                                                <span className="fs-4 fw-bold">Select Co-Host</span>
                                                <div className="col-6">
                                                    <StyledSelect
                                                        className="col-12"
                                                        labelId="my-select-label"
                                                        id="demo-simple-select"
                                                        value={selectProjectCoHost}
                                                        label="Select Co-Host"
                                                        variant="outlined"
                                                        onChange={handleChangeCoHost}
                                                    >
                                                        {projectData.projectTeam?.map((p, i) => (
                                                            <MenuItem key={i} value={p._id}><span className="fs-5">{p.firstName} {p.lastName}</span></MenuItem>
                                                        ))}
                                                    </StyledSelect>
                                                </div>
                                                <button
                                                    onClick={() => handleSubmitCoHost({ projectId: projectData._id, userId: selectProjectCoHost })}
                                                    className="btn fw-bold btn-primary rounded-pill">Add as Co-Host</button>
                                            </div>
                                        </Collapse>
                                        <hr className="m-1" />
                                    </div>
                                    <div className="col-12 d-flex justify-content-between align-items-center">
                                        <span className="fs-3"><b>Team Members</b></span>
                                    </div>
                                    <div className="card p-2 m-2 col-12 bg-transparent border-primary rounded-lg">
                                        {projectData.projectTeam?.length === 0 ? <div>
                                            <span>No Team members</span>
                                        </div> : <div>
                                            {projectData.projectTeam?.map((p, i) => (
                                                <div key={i} style={{
                                                    border: 'solid #0257d8',
                                                    borderWidth: 'thin'
                                                }} className="bg-light p-2 my-1 rounded">
                                                    <div className="d-flex flex-wrap justify-content-between align-items-center ">
                                                        <div className="col-12 col-md-7">
                                                            <span className="fs-4 ">{p?.firstName} {p?.lastName}</span>
                                                        </div>
                                                        <div className="col-12 col-md-5 d-flex justify-content-between align-items-center">
                                                            <button onClick={() => handleDeSelectRequestedMember(projectData._id, p._id)} className="btn fw-bold btn-danger rounded-pill">
                                                                Remove
                                                            </button>
                                                            {teamCollapsible === i ?
                                                                <button onClick={() => setTeamCollapsible(-1)} className="btn fw-bold btn-danger rounded-pill">
                                                                    Close
                                                                </button> :
                                                                <button onClick={() => setTeamCollapsible(i)} className="btn fw-bold btn-primary rounded-pill">
                                                                    View Details
                                                                </button>}
                                                        </div>
                                                    </div>
                                                    <Collapse className="" in={teamCollapsible === i}>
                                                        <div className="py-3 d-flex flex-wrap justify-content-around align-items-start">
                                                            <div className="card col-md-5 col-12 m-1 p-2 bg-transparent border-secondary rounded-lg">
                                                                <div>
                                                                    <span className="fs-4"><b>Work Exprience</b></span>
                                                                    <div>
                                                                        {p?.devSembleProfile?.workExprience.map((w, i) => (
                                                                            <div key={i} className="">
                                                                                <span className="fs-4">{w.title}</span>
                                                                                <div className="d-flex flex-wrap ">
                                                                                    {w?.techUsed.map((t, i) => (
                                                                                        <div key={i} className="bg-primary rounded-pill px-2 me-2 text-white fw-bold">
                                                                                            <span className="">{t}</span>
                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                                <hr className="mb-2" />
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="card col-md-5 col-12 m-1 p-2 bg-transparent border-secondary rounded-lg">
                                                                <div>
                                                                    <span className="fs-4"><b>Education Exprience</b></span>
                                                                    <div>
                                                                        {p?.devSembleProfile?.educationExprience.map((e, i) => (
                                                                            <div key={i} className="">
                                                                                <span className="fs-4">{e.eduTitle}</span>
                                                                                <hr className="mb-2" />
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Collapse>
                                                </div>
                                            ))}
                                        </div>}
                                    </div>
                                    <span className="fs-3"><b>Users Requested</b></span>
                                    <div className="card p-2 m-2 col-12 bg-transparent border-primary rounded-lg">
                                        {projectData.projectTeamRequested?.length === 0 ? <div>
                                            <span>No one requested</span>
                                        </div> : <div>
                                            {projectData.projectTeamRequested?.map((p, i) => (
                                                <div key={i} style={{
                                                    border: 'solid #0257d8',
                                                    borderWidth: 'thin'
                                                }} className="bg-light p-2 my-1 rounded">
                                                    <div className="d-flex flex-wrap justify-content-between align-items-center ">
                                                        <div className="col-12 col-md-7">
                                                            <span className="fs-4 ">{p?.firstName} {p?.middleName} {p?.lastName}</span>
                                                        </div>
                                                        <div className="col-12 col-md-5 d-flex justify-content-between align-items-center">
                                                            <button onClick={() => handleDeSelectRequestedMember(projectData._id, p._id)} className="btn fw-bold btn-danger rounded-pill">
                                                                Deselect
                                                            </button>
                                                            {showCollapsible === i ?
                                                                <button onClick={() => setShowCollapsible(-1)} className="btn fw-bold btn-danger rounded-pill">
                                                                    Close
                                                                </button> :
                                                                <button onClick={() => setShowCollapsible(i)} className="btn fw-bold btn-primary rounded-pill">
                                                                    View Details
                                                                </button>}
                                                        </div>
                                                    </div>
                                                    <Collapse className="" in={showCollapsible === i}>
                                                        <div className="py-3 d-flex flex-wrap justify-content-around align-items-start">
                                                            <div className="card col-md-5 col-12 m-1 p-2 bg-transparent border-secondary rounded-lg">
                                                                <div>
                                                                    <span className="fs-4"><b>Work Exprience</b></span>
                                                                    <div>
                                                                        {p?.devSembleProfile?.workExprience.map((w, i) => (
                                                                            <div key={i} className="">
                                                                                <span className="fs-4">{w.title}</span>
                                                                                <div className="d-flex flex-wrap ">
                                                                                    {w?.techUsed.map((t, i) => (
                                                                                        <div key={i} className="bg-primary rounded-pill px-2 me-2 text-white fw-bold">
                                                                                            <span className="">{t}</span>
                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                                <hr className="mb-2" />
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="card col-md-5 col-12 m-1 p-2 bg-transparent border-secondary rounded-lg">
                                                                <div>
                                                                    <span className="fs-4"><b>Education Exprience</b></span>
                                                                    <div>
                                                                        {p?.devSembleProfile?.educationExprience.map((e, i) => (
                                                                            <div key={i} className="">
                                                                                <span className="fs-4">{e.eduTitle}</span>
                                                                                <hr className="mb-2" />
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Collapse>
                                                </div>
                                            ))}
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        </Collapse>
                        <Collapse in={sudoRequest.length > 0}>
                            <div className="card border-0 bg-transparent my-3 p-2">
                                <div className="d-flex flex-wrap justify-content-between align-items-start">
                                    <div className="">
                                        <span className="fs-3 fw-bold">Team Members you selected</span>
                                    </div>
                                    {sudoRequest.length === 0 ? null :
                                        <button onClick={() => onSubmitRequests(projectData._id, sudoUserRequestIds)} className="btn my-2 btn-success col-md-4 rounded-pill">
                                            <span className="ps-lg-3">Send Requests</span>
                                        </button>}
                                </div>
                                <div className="conatiner-fluid card p-2 bg-transparent " style={{ height: "50vh", overflowY: "scroll" }}>
                                    {sudoRequest.map((s, i) => (
                                        <div key={i} style={{
                                            border: 'solid #0257d8',
                                            borderWidth: 'thin'
                                        }} className="bg-light p-2 my-1 rounded">
                                            <div className="d-flex flex-wrap justify-content-between align-items-center ">
                                                <div className="col-12 col-md-6">
                                                    <span className="fs-4 fw-bold">{s.userId.firstName} {s.userId.lastName}</span>
                                                </div>
                                                <div className="col-12 col-md-6 d-flex justify-content-between align-items-center">
                                                    <button onClick={() => handleRemoveTeamRequest(s)} className="btn fw-bold btn-danger rounded-pill">
                                                        Deselect
                                                    </button>
                                                    {showSelectedCollapsible === i ?
                                                        <button onClick={() => setShowSelectedCollapsible(-1)} className="btn fw-bold btn-danger rounded-pill">
                                                            Close
                                                        </button> :
                                                        <button onClick={() => setShowSelectedCollapsible(i)} className="btn fw-bold btn-primary rounded-pill">
                                                            View Details
                                                        </button>}
                                                </div>
                                            </div>
                                            <div className="">
                                                <Collapse className="" in={showSelectedCollapsible === i}>
                                                    <div className="py-3 d-flex flex-wrap justify-content-around align-items-start">
                                                        <div className="card col-md-5 col-12 m-1 p-2 bg-transparent border-secondary rounded-lg">
                                                            <div>
                                                                <span className="fs-4"><b>Work Exprience</b></span>
                                                                <div>
                                                                    {s?.workExprience.map((w, i) => (
                                                                        <div key={i} className="">
                                                                            <span className="fs-4">{w.title}</span>
                                                                            <div className="d-flex flex-wrap ">
                                                                                {w?.techUsed.map((t, i) => (
                                                                                    <div key={i} className="bg-primary rounded-pill px-2 me-2 text-white fw-bold">
                                                                                        <span className="">{t}</span>
                                                                                    </div>
                                                                                ))}
                                                                            </div>
                                                                            <hr className="mb-2" />
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="card col-md-5 col-12 m-1 p-2 bg-transparent border-secondary rounded-lg">
                                                            <div>
                                                                <span className="fs-4"><b>Education Exprience</b></span>
                                                                <div>
                                                                    {s?.educationExprience.map((e, i) => (
                                                                        <div key={i} className="">
                                                                            <span className="fs-4">{e.eduTitle}</span>
                                                                            <hr className="mb-2" />
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Collapse>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </Collapse>
                    </div>
                </div>
            </AllowAdvancedAccount>
        </AuthNavbar>
    )
}

export default ManageTeam