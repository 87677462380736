import React, { useState, useEffect, useRef } from "react";
import Editor from "@monaco-editor/react";
import { io } from "socket.io-client";
import { v4 as uuidv4 } from "uuid";
import { useParams } from "react-router-dom";
import { useAuthStore } from "../../store/store";
import AuthNavbar from "../AuthNavbar";

function CollaborativeEditor() {
    const user = useAuthStore((state) => state.user);
    const userName = user.user.firstName;
    const projectId = useParams().pId;
    const [code, setCode] = useState(localStorage.getItem("savedCode") || "// Start coding...");
    const [fileName, setFileName] = useState(localStorage.getItem("fileName"));
    const [language, setLanguage] = useState(localStorage.getItem("language") || "javascript");
    const [remoteCursors, setRemoteCursors] = useState({});
    const socketRef = useRef();
    const editorRef = useRef();
    const monacoRef = useRef();
    const userId = useRef(uuidv4());

    const getLanguageFromExtension = (fileName) => {
        const extension = fileName?.split(".").pop().toLowerCase();
        const languageMap = {
            js: "javascript",
            jsx: "javascript",
            ts: "typescript",
            tsx: "typescript",
            html: "html",
            css: "css",
            json: "json",
            py: "python",
            java: "java",
            cpp: "cpp",
            c: "c",
            cs: "csharp",
            php: "php",
            rb: "ruby",
            go: "go",
            swift: "swift",
            rs: "rust",
            kt: "kotlin",
            xml: "xml",
            sql: "sql",
            sh: "shell",
            md: "markdown",
            txt: "plaintext",
        };
        return languageMap[extension] || "plaintext";
    };

    useEffect(() => {
        socketRef.current = io(process.env.REACT_APP_SOCKET);

        socketRef.current.emit("joinProjecteditor", { projectId, userName });

        socketRef.current.on("codeChange", (newCode) => {
            setCode(newCode);
            localStorage.setItem("savedCode", newCode);
        });

        socketRef.current.on("cursorChange", (cursorData) => {
            if (cursorData.userId !== userId.current) {
                setRemoteCursors((prevCursors) => ({
                    ...prevCursors,
                    [cursorData.userId]: cursorData,
                }));
            }
        });

        socketRef.current.on("activeUsers", (users) => {
            // Handle active users list
        });

        socketRef.current.on("fileImported", ({ newCode, newFileName }) => {
            setCode(newCode);
            setFileName(newFileName);
            const detectedLanguage = getLanguageFromExtension(newFileName);
            setLanguage(detectedLanguage);
            localStorage.setItem("savedCode", newCode);
            localStorage.setItem("fileName", newFileName);
            localStorage.setItem("language", detectedLanguage);
        });

        socketRef.current.on("languageChange", (data) => {
            const { language } = data;
            setLanguage(language);
        });

        return () => {
            socketRef.current.disconnect();
        };
    }, [projectId]);

    const handleEditorChange = (value) => {
        setCode(value);
        socketRef.current.emit("codeChange", { projectId, code: value });
        localStorage.setItem("savedCode", value);
    };

    const handleCursorChange = () => {
        const position = editorRef.current.getPosition();
        const cursorData = {
            userId: userId.current,
            userName,
            position,
        };
        socketRef.current.emit("cursorChange", { projectId, cursorData });
    };

    const handleEditorDidMount = (editor, monaco) => {
        editorRef.current = editor;
        monacoRef.current = monaco;
        editor.onDidChangeCursorPosition(handleCursorChange);

        // Set initial cursor position for this user
        handleCursorChange();
    };

    const renderRemoteCursors = () => {
        Object.values(remoteCursors).forEach((cursorData) => {
            const { userId, userName, position } = cursorData;

            const range = new monacoRef.current.Range(
                position.lineNumber,
                position.column,
                position.lineNumber,
                position.column
            );
            const decoration = {
                range,
                options: {
                    className: "remote-cursor",
                    afterContentClassName: `remote-cursor-label`,
                    after: {
                        contentText: userName,
                        inlineClassName: "remote-cursor-label",
                    },
                },
            };

            editorRef.current.deltaDecorations([], [decoration]);
        });
    };

    useEffect(() => {
        if (editorRef.current && monacoRef.current) {
            renderRemoteCursors();
        }
    }, [remoteCursors]);

    const handleImportFile = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const content = e.target.result;
                setCode(content);
                socketRef.current.emit("codeChange", { projectId, code: content });
            };
            reader.readAsText(file);
        }
    };

    const handleSaveFile = () => {
        const blob = new Blob([code], { type: "text/plain" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = fileName || "untitled.txt";
        a.click();
        window.URL.revokeObjectURL(url);
    };

    const handleLanguageChange = (event) => {
        const selectedLanguage = event.target.value;
        setLanguage(selectedLanguage);
        socketRef.current.emit("languageChange", { projectId, language: selectedLanguage });
    };

    return (
        <div className="container-fluid mt-4">
            <div className="">
                <div className="d-flex flex-wrap justify-content-between align-items-center">
                </div>
                <div className="">
                    <div className="card">
                        <div className="card-header py-1 d-flex flex-wrap justify-content-between align-items-center">
                            <div className="mb-2">
                                <select
                                    className="form-select"
                                    value={language}
                                    onChange={handleLanguageChange}
                                >
                                    <option value="javascript">JavaScript</option>
                                    <option value="typescript">TypeScript</option>
                                    <option value="html">HTML</option>
                                    <option value="css">CSS</option>
                                    <option value="json">JSON</option>
                                    <option value="python">Python</option>
                                    <option value="java">Java</option>
                                    <option value="cpp">C++</option>
                                    <option value="c">C</option>
                                    <option value="csharp">C#</option>
                                    <option value="php">PHP</option>
                                    <option value="ruby">Ruby</option>
                                    <option value="go">Go</option>
                                    <option value="swift">Swift</option>
                                    <option value="rust">Rust</option>
                                    <option value="kotlin">Kotlin</option>
                                    <option value="xml">XML</option>
                                    <option value="sql">SQL</option>
                                    <option value="shell">Shell</option>
                                    <option value="markdown">Markdown</option>
                                    <option value="plaintext">Plain Text</option>
                                </select>
                            </div>
                            <div>
                                <h5>{fileName}</h5>
                            </div>
                            <div className="py-2">
                                <input type="file" className="form-control" onChange={handleImportFile} />
                            </div>
                        </div>
                        <div className="card-body p-0" style={{ height: "60vh" }}>
                            <Editor
                                language={language}
                                value={code}
                                onChange={handleEditorChange}
                                theme="vs-dark"
                                editorDidMount={handleEditorDidMount}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CollaborativeEditor;
