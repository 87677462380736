import { useState, useEffect } from "react"
import { useAuthStore } from '../../store/store';
import { getUserProfile } from "../../actions/userProfileActions";
import { Link } from "react-router-dom";

const TeamJoined = () => {

    const data = useAuthStore(state => state.user)
    const [userData, setUserData] = useState({})
    const [refresh, setRefresh] = useState(false)

    useEffect(() => {
        getUserProfile(data.user._id).then(res => {
            setUserData(res)
        })
    }, [refresh])

    return (
        <div className="col-md-9 col-12 bg-white">
            <span className="fs-2"><b>Team Joined</b></span>
            <div className="d-flex flex-wrap justify-content-center">
                {userData?.teamJoined?.length !== 0 ? <div style={{width: '100%'}}>{userData?.teamJoined?.map((a, i) => (
                    <div key={i} className="py-2 col-12">
                        <div className="shadow bg-transparent my-3 p-2">
                            <div className="d-flex col-12 justify-content-between align-items-center">
                                <Link to={`/dashboard/teams-joined/single-project/${a._id}`} className="" state={a._id}><h3 className="fw-bold">{a.projectTitle}
                                    {a.isDeleted ? <span> (Deleted)</span> : null}</h3></Link>
                            </div>
                            <div className="col-12 d-flex flex-wrap ">
                                {a?.projectTech.map((p, i) => (
                                    <div key={i} className="bg-primary rounded-pill px-2 me-2">
                                        <span className="fs-6 fw-bold m-0 text-white">{p}</span>
                                    </div>
                                ))}
                            </div>
                            <p className="m-0 fs-5">{a?.projectDescription}</p>
                            <hr />
                            <div className="py-3">
                                <h4>Project Current Requirements</h4>
                                {a?.projectCurrentReq.map((p, i) => (
                                    <div key={i}>
                                        <div className="d-flex align-items-start justify-content-start">
                                            <span className="fs-5 col-1 text-center">{i + 1}.</span>
                                            <div className="col-9">
                                                <p className="m-0 fs-5">{p?.title}</p>
                                                <p className="m-0 fs-5">{p?.description}</p>
                                            </div>
                                        </div>
                                        {a?.projectCurrentReq.length-1 === i ?null : <hr className="mx-2 mx-md-5" />}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                ))}</div> : <div className="shadow col-12 text-center p-2 my-2">
                    <h4>No Teams joined</h4>
                </div>}
            </div>
        </div>
    )
}

export default TeamJoined