import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import AuthNavbar from "../AuthNavbar"
import { getReviewList } from "../../actions/reviewActions"
import { Outlet } from "react-router-dom"
import { useAuthStore } from '../../store/store';
import { Collapse } from "@mui/material"
import { PreventNonVerifiedReviewers } from "../../manageRoutes/ProtectedRoutes"

const ReviewPage = () => {
    
    const data = useAuthStore(state => state.user)
    const [reviewList, setReviewList] = useState([])
    const [listedUser, setListedUser] = useState({})
    const [isOpen, setIsOpen] = useState(false)
    const [refresh, setRefresh] = useState(false)

    useEffect(() => {
        getReviewList(data.user._id).then(res => {
            setReviewList(res)
        })
        setIsOpen(false)
        setRefresh(false)
    }, [refresh])

    const displayList = () => {
        return reviewList.map((r, i) => (
            <div className="" key={i}>
                <div className="d-flex justify-content-between align-items-center border border-dark rounded p-2 my-3">
                    <div className="fs-3">
                        {r.userId.firstName} {r.userId.lastName}
                    </div>
                    <div>
                        <Link to={`${r._id}`} state={r.userId} className="text-white text-decoration-none">
                            <button onClick={() => {setIsOpen(true)
                            setListedUser(r)}} className="btn btn-primary rounded-pill">
                                Review
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        ))
    }

    return(
        <AuthNavbar>
            <PreventNonVerifiedReviewers>
            <div className="py-3 container-fluid">
                <div align="center" className="display-3">
                    <div>
                        List of users to review
                    </div>
                    <div>
                        <Link to="/post-review-lists">
                            <button className="btn btn-outline-success ">
                                View Post Review
                            </button>
                        </Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <Collapse in={!isOpen}>
                            {displayList()}                        
                        </Collapse>
                        <Collapse in={isOpen}>
                            {listedUser.userId !== undefined ? 
                            <div className="container-fluid">
                                <div>
                                    <Link to="/review-lists">
                                        <button onClick={() => setIsOpen(false)} className="btn btn-outline-danger ">
                                            Back
                                        </button>
                                    </Link>
                                </div>
                                <div className="">
                                    <span className="fs-3">{listedUser?.userId.firstName} {listedUser?.userId.lastName}</span>
                                </div>
                                <div className="">
                                    Public Score: {listedUser?.userId.publicScore}
                                </div>
                                <div>
                                    Expert Score: {listedUser?.userId.expertScore}
                                </div>
                            </div> : null}
                        </Collapse>
                    </div>
                    <div className="col-md-8 p-md-5 p-3">
                        <Outlet context={[isOpen, setIsOpen, setRefresh]}/>
                    </div>
                </div>
            </div>
            </PreventNonVerifiedReviewers>
        </AuthNavbar>
    )
}

export default ReviewPage