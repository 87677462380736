import { useEffect, useState } from "react"
// import { getScore } from "../actions/reviewActions"
import { fetchRankedUsers } from "../actions/projectActions"

const PublicDisplayRankList = () => {

    const [list, setList] = useState([])

    useEffect(() => {
        fetchRankedUsers().then(res => {
            setList(res)
        }).catch(err => {
            console.log(err)
        })
    }, [])

    return (
        <div>
            <div className="">
                PublicDisplayRankList
                <div>
                    <div className="container-fluid d-flex flex-wrap justify-content-center align-items-center">
                        <div className="card bg-transparent col-md-6 col-10">
                            {list.map((l, i) => (
                                <div key={i}>
                                    <div className="d-flex flex-wrap justify-content-between align-items-center">
                                        <div>{l.userId.firstName}</div>
                                        {/* <div>{JSON.stringify(l.precentageTrend)}</div>
                                        <div>{l.changeInPercentage}</div> */}
                                        <div>{l.rankRange === null ? <div>Null</div> : l.rankRange}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PublicDisplayRankList