import { useState, useEffect } from "react"
import { useAuthStore } from '../../store/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquareCheck, faSquareXmark } from '@fortawesome/free-solid-svg-icons'
import { getUserProfile } from "../../actions/userProfileActions";
import { acceptTeamRequest, rejectTeamRequest } from "../../actions/projectActions"
import { useNavigate } from "react-router-dom";

const TeamRequested = () => {

    const data = useAuthStore(state => state.user)
    const [userData, setUserData] = useState({})
    const [refresh, setRefresh] = useState(false)

    const navigate = useNavigate()

    useEffect(() => {
        getUserProfile(data.user._id).then(res => {
            setUserData(res)
        })
    }, [refresh])

    const handleAcceptRequest = (data) => {
        acceptTeamRequest(data).then(res => {
            if (res) {
                setRefresh(true)
                navigate('/dashboard/teams-joined')
            }
        }).catch(err => {
            console.log(err)
        })
        setRefresh(false)
    }



    const handleRejectRequest = (data) => {
        rejectTeamRequest(data).then(res => {
            if (res) {
                setRefresh(true)
            }
        }).catch(err => {
            console.log(err)
        })
        setRefresh(false)
    }

    return (
        <div className="col-md-9 col-12 bg-white">
            <span className="fs-2"><b>Team Requested</b></span>
            <div className="d-flex flex-wrap justify-content-between">
                {userData?.pendingRequests?.length !== 0 ? <div style={{width: '100%'}}>{userData?.pendingRequests?.map((a, i) => (
                    <div key={i} className="py-2 col-12">
                        <div className="shadow bg-transparent my-3 p-2">
                            <div className="d-flex col-12 justify-content-between align-items-center">
                                <div className="col-7">
                                    <h3 className="">{a.projectTitle}</h3>
                                </div>
                                <div className="col-5 border-start border-3 d-flex flex-wrap justify-content-around align-items-center">
                                    <button onClick={() => handleAcceptRequest({ projectId: a._id, userId: data?.user._id })} className="btn btn-success rounded-pill">
                                        <span className="d-md-inline d-none fw-bold">Accept</span> <FontAwesomeIcon icon={faSquareCheck} />
                                    </button>
                                    <button onClick={() => handleRejectRequest({ projectId: a._id, userId: data?.user._id })} className="btn btn-danger rounded-pill">
                                        <span className="d-md-inline d-none fw-bold">Reject</span> <FontAwesomeIcon icon={faSquareXmark} />
                                    </button>
                                </div>
                            </div>
                            <div className="col-12 d-flex flex-wrap ">
                                {a?.projectTech.map((p, i) => (
                                    <div key={i} className="bg-primary rounded-pill px-2 me-2">
                                        <span className="fs-6 fw-bold text-white">{p}</span>
                                    </div>
                                ))}
                            </div>
                            <p className="m-0 fs-5">{a?.projectDescription}</p>
                            <hr />
                            <div className="py-3">
                                <h4>Project Current Requirements</h4>
                                {a?.projectCurrentReq.map((p, i) => (
                                    <div key={i}>
                                        <div className="d-flex align-items-start justify-content-start">
                                            <span className="fs-5 col-1 text-center">{i + 1}.</span>
                                            <div className="col-9">
                                                <p className="m-0 fs-5">{p?.title}</p>
                                                <p className="m-0 fs-5">{p?.description}</p>
                                            </div>
                                        </div>
                                        {a?.projectCurrentReq.length-1 === i ?null : <hr className="mx-2 mx-md-5" />}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                ))}</div>: <div className="shadow col-12 text-center p-2 my-2">
                <h4>No Teams joined</h4>
            </div>}
            </div>
        </div>
    )
}

export default TeamRequested