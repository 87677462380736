import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import { fetchEmail } from '../actions/authActions';
import { useAuthStore } from '../store/store';
import { Link } from 'react-router-dom';
import profileCreation from "./assets/profile_creation.png"
import profileRanking from "./assets/profile_ranking.png"
import profileMatching from './assets/profile_matching_collaboration.png'
import reviewFeedBack from './assets/review_feedback.png'
import collaboration from './assets/collaboration.png'
import learnAndDevelopment from './assets/learn_and_development.png'
import lander_image_1 from './assets/lander_image_1.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Navbar from './Navbar';
import Footer from '../freeAccess/Footer';

const LanderPage = () => {

    const [values, setValues] = useState({
        email: '',
        error: '',
        errorMessage: ''
    })

    const userData = useAuthStore(state => state.user)
    const setStateUser = useAuthStore(state => state.setUser)

    const navigate = useNavigate()

    const emailRegex = /\S+@\S+\.\S+/;

    const { email, errorMessage } = values

    const handleSubmit = (email) => {
        if (emailRegex.test(email)) {
            fetchEmail({ email: email }).then(res => {
                if (res.userExist === false) {
                    navigate('/register', { state: { sessionId: res.sessionId } })
                }
                if (res.userExist === true) {
                    navigate('/login', { state: { sessionId: res.sessionId } })
                }
            })
        } else {
            setValues({ ...values, errorMessage: "Please enter a valid Email" })
        }
    }

    const handleChange = name => event => {
        setValues({ ...values, error: '', errorMessage: '', [name]: event.target.value });
    };

    const onClickLogout = () => {
        setStateUser(null)
        localStorage.removeItem('user')
    }

    const [showNavbar, setShowNavbar] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY >= window.innerHeight / 2) {
                setShowNavbar(true);
            } else {
                setShowNavbar(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <div className={`fixed-top navbar-home ${showNavbar ? 'visible-home' : ''}`}>
                {showNavbar && (
                    <Navbar />
                )}
            </div>
            <header className='' style={{ overflowX: 'hidden' }}>
                <div className='d-flex flex-wrap justify-content-around align-items-center py-3 py-md-5' style={{ height: '70vh' }}>
                    <div className='conatiner-fluid text-start order-md-1 order-2 p-3'>
                        <h1 className='d-flex flex-wrap display-4 fw-bold'>
                            <div className='' data-aos="fade-left">Find</div>
                            <div className='px-3' data-aos="fade-left" data-aos-delay="200">your</div>
                            <div className='' data-aos="fade-left" data-aos-delay="300"> Perfect</div>
                        </h1>
                        <h1 className='d-flex flex-wrap display-3 fw-bold'>
                            <div className='' data-aos="fade-left" data-aos-delay="100">Dev</div>
                            <div className='px-md-3 px-1' data-aos="fade-left" data-aos-delay="200">Team</div>
                            <div className='px-md-3 px-1' data-aos="fade-left" data-aos-delay="300">and</div>
                            <div className='' data-aos="fade-left" data-aos-delay="400">Project</div>
                        </h1>
                        <h1 className='d-flex flex-wrap align-items-center display-1 fw-bold'>
                            <div className='display-3 fw-bold' data-aos="fade-left" data-aos-delay="300">with </div>
                            <div className='text-primary px-md-3 px-1' data-aos="fade-left" data-aos-delay="400">devSemble!</div>
                        </h1>
                    </div>
                    <div className='col-md-4 d-none d-lg-block col-10 order-md-2 order-1'>
                        <div align="center" className='text-center container' data-aos="fade-up" data-aos-delay="500">
                            <img src={lander_image_1} className='img-fluid img-shadow' alt='devSemble Logo' />
                        </div>
                    </div>
                </div>
                <div className='py-md-5 py-3 p-2'>
                    <h3 className='text-center' data-aos="fade-up" data-aos-delay='900' data-aos-animation="ease-in-out" data-aos-anchor="bottom-bottom">
                        Welcome to <span className='text-primary' style={{ fontWeight: "bolder" }}>devSemble</span> - Where Talent Meets Opportunity!
                    </h3>
                    <div className='py-md-5 py-3 text-center' data-aos="fade-up" data-aos-delay='1000' data-aos-animation="ease-in-out">
                        <a href='#get-started-section'>
                            <button className='btn btn-primary fw-bold rounded-pill col-md-4 col-10'>
                                Get Started
                            </button>
                        </a>
                    </div>
                </div>
            </header>
            <div className='container-fluid col-md-10 col-11 py-md-5 py-3'>
                <div className='text-center py-5'>
                    <h1 className='fw-normal display-3'><b>What is <span className='text-primary fw-bold'>devSemble</span></b></h1>
                </div>
                <div className='d-flex flex-wrap justify-content-center align-items-center '>
                    <div className="d-flex flex-wrap justify-content-center align-items-center">
                        <div className="col-lg-3 col-11 card border-0" data-aos="fade-up" data-aos-delay="500">
                            <img src={profileCreation} className="card-img-top" alt="..." />
                            <div className="card-body text-center">
                                <h4 className="card-title fw-bold py-2">Profile Creation and Showcasing</h4>
                                <h5 className="card-text py-2">Create detailed profiles that highlight your skills, experiences, project contributions, and portfolio items.</h5>
                            </div>
                        </div>
                        <div className="card bg-transparent col-lg-1 col-11 d-lg-block d-none text-center border-0" data-aos="fade-right" data-aos-delay="600">
                            <FontAwesomeIcon size="4x" icon={faArrowRight} />
                        </div>
                        <div className="card bg-transparent col-lg-1 col-11 d-block d-lg-none text-center border-0" data-aos="fade-down" data-aos-delay="500">
                            <FontAwesomeIcon size="3x" icon={faArrowDown} />
                        </div>
                        <div className="col-lg-3 col-11 card border-0" data-aos="fade-up" data-aos-delay="500">
                            <img src={profileRanking} className="card-img-top" alt="..." />
                            <div className="card-body text-center">
                                <h4 className="card-title fw-bold py-2">Automated Profile Review and Ranking</h4>
                                <h5 className="card-text py-2">An advanced algorithm evaluates profiles based on predefined criteria, ensuring consistency and fairness.</h5>
                            </div>
                        </div>
                        <div className="card bg-transparent col-lg-1 col-11 d-lg-block d-none text-center border-0" data-aos="fade-right" data-aos-delay="800">
                            <FontAwesomeIcon size="4x" icon={faArrowRight} />
                        </div>
                        <div className="card bg-transparent col-lg-1 col-11 d-block d-lg-none text-center border-0" data-aos="fade-down" data-aos-delay="500">
                            <FontAwesomeIcon size="3x" icon={faArrowDown} />
                        </div>
                        <div className="col-lg-3 col-11 card border-0" data-aos="fade-up" data-aos-delay="900">
                            <img src={profileMatching} className="card-img-top" alt="..." />
                            <div className="card-body text-center">
                                <h4 className="card-title fw-bold py-2">Project Matching and Collaboration</h4>
                                <h5 className="card-text py-2">Matches project listers with suitable candidates based on profile scores and rankings.</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-11 d-flex flex-wrap justify-content-between align-items-center'>
                    <div className="card col border-0">
                    </div>
                    <div className="card col border-0">
                    </div>
                    <div className="card bg-transparent col border-0" data-aos="fade-up" data-aos-delay="500">
                        <FontAwesomeIcon className='d-lg-none d-block' size="3x" icon={faArrowDown} />
                    </div>
                    <div className="card col border-0">
                    </div>
                    <div className="card bg-transparent col border-0" data-aos="fade-up" data-aos-delay="500">
                        <FontAwesomeIcon className='d-lg-block d-none' size="4x" icon={faArrowDown} />
                    </div>
                </div>
                <div className='d-flex d-lg-block d-none flex-wrap justify-content-center align-items-center '>
                    <div className="d-flex flex-wrap justify-content-center align-items-center">
                        <div className="col-lg-3 col-11 card border-0" data-aos="fade-up" data-aos-delay="900">
                            <img src={reviewFeedBack} className="card-img-top" alt="..." />
                            <div className="card-body text-center">
                                <h4 className="card-title fw-bold py-2">Review and Feedback System</h4>
                                <h5 className="card-text py-2">Users can review and provide feedback on each other's work, fostering a culture of constructive criticism and continuous improvement.</h5>
                            </div>
                        </div>
                        <div className="card bg-transparent col-lg-1 col-11 d-lg-block d-none text-center border-0" data-aos="fade-left" data-aos-delay="800">
                            <FontAwesomeIcon size="4x" icon={faArrowLeft} />
                        </div>
                        <div className="card bg-transparent col-lg-1 col-11 d-block d-lg-none text-center border-0" data-aos="fade-down" data-aos-delay="500">
                            <FontAwesomeIcon size="3x" icon={faArrowDown} />
                        </div>
                        <div className="card col-lg-3 col-11 border-0" data-aos="fade-up" data-aos-delay="700">
                            <img src={collaboration} className="card-img-top" alt="..." />
                            <div className="card-body text-center">
                                <h4 className="card-title fw-bold py-2">Collaborative Tools and Features</h4>
                                <h5 className="card-text py-2">Includes features such as chat rooms and task assignment tools, enabling seamless communication and coordination among team members.</h5>
                            </div>
                        </div>
                        <div className="card bg-transparent col-lg-1 col-11 d-lg-block d-none text-center border-0" data-aos="fade-left" data-aos-delay="600">
                            <FontAwesomeIcon size="4x" icon={faArrowLeft} />
                        </div>
                        <div className="card bg-transparent col-lg-1 col-11 d-block d-lg-none text-center border-0" data-aos="fade-down" data-aos-delay="500">
                            <FontAwesomeIcon size="3x" icon={faArrowDown} />
                        </div>
                        <div className="card col-lg-3 col-11 border-0" data-aos="fade-up" data-aos-delay="500">
                            <img src={learnAndDevelopment} className="card-img-top" alt="..." />
                            <div className="card-body text-center">
                                <h4 className="card-title fw-bold py-2">Learning and Development</h4>
                                <h5 className="card-text py-2">Gain valuable practical experience and enhance your portfolio by working on real-world projects.</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='d-flex d-lg-none d-block flex-wrap justify-content-center align-items-center'>
                    <div className="d-flex flex-wrap justify-content-center align-items-center">
                        <div className="card col-lg-3 col-11 border-0" data-aos="fade-up" data-aos-delay="500">
                            <img src={learnAndDevelopment} className="card-img-top" alt="..." />
                            <div className="card-body text-center">
                                <h4 className="card-title fw-bold py-2">Learning and Development</h4>
                                <h5 className="card-text py-2">Gain valuable practical experience and enhance your portfolio by working on real-world projects.</h5>
                            </div>
                        </div>
                        <div className="card bg-transparent col-lg-1 col-11 d-lg-block d-none text-center border-0" data-aos="fade-up" data-aos-delay="700">
                            <FontAwesomeIcon size="4x" icon={faArrowLeft} />
                        </div>
                        <div className="card bg-transparent col-lg-1 col-11 d-block d-lg-none text-center border-0" data-aos="fade-down" data-aos-delay="700">
                            <FontAwesomeIcon size="3x" icon={faArrowDown} />
                        </div>
                        <div className="card col-lg-3 col-11 border-0" data-aos="fade-up" data-aos-delay="500">
                            <img src={collaboration} className="card-img-top" alt="..." />
                            <div className="card-body text-center">
                                <h4 className="card-title fw-bold py-2">Collaborative Tools and Features</h4>
                                <h5 className="card-text py-2">Includes features such as chat rooms and task assignment tools, enabling seamless communication and coordination among team members.</h5>
                            </div>
                        </div>
                        <div className="card bg-transparent col-lg-1 col-11 d-lg-block d-none text-center border-0" data-aos="fade-up" data-aos-delay="700">
                            <FontAwesomeIcon size="4x" icon={faArrowLeft} />
                        </div>
                        <div className="card bg-transparent col-lg-1 col-11 d-block d-lg-none text-center border-0" data-aos="fade-down" data-aos-delay="700">
                            <FontAwesomeIcon size="3x" icon={faArrowDown} />
                        </div>
                        <div className="card col-lg-3 col-11 border-0" data-aos="fade-up" data-aos-delay="500">
                            <img src={reviewFeedBack} className="card-img-top" alt="..." />
                            <div className="card-body text-center">
                                <h4 className="card-title fw-bold py-2">Review and Feedback System</h4>
                                <h5 className="card-text py-2">Users can review and provide feedback on each other's work, fostering a culture of constructive criticism and continuous improvement.</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container-fluid py-5'>
                <div className='py-3 text-center'>
                    <h1 className='display-3 fw-normal'><b>Why</b> <span className='text-primary fw-bold'>devSemble</span></h1>
                </div>
                <div className='d-flex flex-wrap justify-content-center align-items-center '>
                    <div className='py-5 col-md-8 col-11'>
                        <div className='d-flex flex-wrap justify-content-between align-items-start'>
                            <div className='py-3 col' data-aos="fade-up" data-aos-delay="500">
                                <div style={{ position: 'relative', paddingTop: '6vh', paddingLeft: '3vh' }}>
                                    <div className='' style={{ position: 'absolute', top: '0', left: '0', padding: '', zIndex: 2 }}>
                                        <h1 className='display-1 text-primary fw-bold' style={{
                                            background: 'linear-gradient(to bottom, #0275d8, #ffffff)',
                                            WebkitBackgroundClip: 'text',
                                            WebkitTextFillColor: 'transparent',
                                            MozBackgroundClip: 'text',
                                            MozTextFillColor: 'transparent',
                                        }}>01</h1>
                                    </div>
                                    <div className='rounded' style={{ position: 'relative', top: "0vh", zIndex: 3 }}>
                                        <h1 className='display-4 fw-bold' >Comprehensive Platform</h1>
                                        <h3 className=''>Bridging the gap between learning and professional experience.</h3>
                                    </div>
                                </div>
                            </div>
                            <div className='py-3 col' data-aos="fade-up" data-aos-delay="700">
                                <div style={{ position: 'relative', paddingTop: '6vh', paddingLeft: '3vh', }}>
                                    <div className='' style={{ position: 'absolute', top: '0', left: '0', zIndex: 2 }}>
                                        <h1 className='display-1 text-primary fw-bold' style={{
                                            background: 'linear-gradient(to bottom, #0275d8, #ffffff)',
                                            WebkitBackgroundClip: 'text',
                                            WebkitTextFillColor: 'transparent',
                                            MozBackgroundClip: 'text',
                                            MozTextFillColor: 'transparent',
                                        }}>02</h1>
                                    </div>
                                    <div className='rounded' style={{ position: 'relative', top: "0vh", zIndex: 3 }}>
                                        <h1 className='display-4 fw-bold'>Objective Evaluations</h1>
                                        <h3 className=''>Automated profile review and ranking for fairness and efficiency.</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex flex-wrap justify-content-between align-items-start'>
                            <div className='py-3 col' data-aos="fade-up" data-aos-delay="500">
                                <div style={{ position: 'relative', paddingTop: '6vh', paddingLeft: '3vh', }}>
                                    <div className='' style={{ position: 'absolute', top: '0', left: '0', zIndex: 2 }}>
                                        <h1 className='display-1 text-primary fw-bold' style={{
                                            background: 'linear-gradient(to bottom, #0275d8, #ffffff)',
                                            WebkitBackgroundClip: 'text',
                                            WebkitTextFillColor: 'transparent',
                                            MozBackgroundClip: 'text',
                                            MozTextFillColor: 'transparent',
                                        }}>03</h1>
                                    </div>
                                    <div className='rounded' style={{ position: 'relative', top: "0vh", zIndex: 3 }}>
                                        <h1 className='display-4 fw-bold'>Real-time Collaboration</h1>
                                        <h3 className=''>Tools to ensure seamless project management and communication.</h3>
                                    </div>
                                </div>
                            </div>
                            <div className='py-3 col' data-aos="fade-up" data-aos-delay="700">
                                <div style={{ position: 'relative', paddingTop: '6vh', paddingLeft: '3vh', }}>
                                    <div className='' style={{ position: 'absolute', top: '0', left: '0', zIndex: 2 }}>
                                        <h1 className='display-1 text-primary fw-bold' style={{
                                            background: 'linear-gradient(to bottom, #0275d8, #ffffff)',
                                            WebkitBackgroundClip: 'text',
                                            WebkitTextFillColor: 'transparent',
                                            MozBackgroundClip: 'text',
                                            MozTextFillColor: 'transparent',
                                        }}>04</h1>
                                    </div>
                                    <div className='rounded' style={{ position: 'relative', top: "0vh", zIndex: 3 }}>
                                        <h1 className='display-4 fw-bold'>Practical Experience</h1>
                                        <h3 className=''>Opportunities to work on real-world projects and build your portfolio.</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex flex-wrap justify-content-between align-items-start'>
                            <div className='py-3 col' data-aos="fade-up" data-aos-delay="500">
                                <div style={{ position: 'relative', paddingTop: '6vh', paddingLeft: '3vh', }}>
                                    <div className='' style={{ position: 'absolute', top: '0', left: '0', zIndex: 2 }}>
                                        <h1 className='display-1 text-primary fw-bold' style={{
                                            background: 'linear-gradient(to bottom, #0275d8, #ffffff)',
                                            WebkitBackgroundClip: 'text',
                                            WebkitTextFillColor: 'transparent',
                                            MozBackgroundClip: 'text',
                                            MozTextFillColor: 'transparent',
                                        }}>05</h1>
                                    </div>
                                    <div className='rounded' style={{ position: 'relative', top: "0vh", zIndex: 3 }}>
                                        <h1 className='display-4 fw-bold'>Educational Engagement</h1>
                                        <h3 className=''>A platform for students to apply their learning practically.</h3>
                                    </div>
                                </div>
                            </div>
                            <div className='py-3 col' data-aos="fade-up" data-aos-delay="700">
                                <div style={{ position: 'relative', paddingTop: '6vh', paddingLeft: '3vh', }}>
                                    <div className='' style={{ position: 'absolute', top: '0', left: '0', zIndex: 2 }}>
                                        <h1 className='display-1 text-primary fw-bold' style={{
                                            background: 'linear-gradient(to bottom, #0275d8, #ffffff)',
                                            WebkitBackgroundClip: 'text',
                                            WebkitTextFillColor: 'transparent',
                                            MozBackgroundClip: 'text',
                                            MozTextFillColor: 'transparent',
                                        }}>06</h1>
                                    </div>
                                    <div className='rounded' style={{ position: 'relative', top: "0vh", zIndex: 3 }}>
                                        <h1 className='display-4 fw-bold'>Feedback and Growth</h1>
                                        <h3 className=''>Receive constructive feedback on your work, enabling continuous improvement and skill enhancement.</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className='text-center' data-aos="fade-up" data-aos-delay="300">
                    <h1 className='display-2 fw-normal'>Transform Your Freelancing Journey<br />with <span className='text-primary'><b>devSemble!</b></span></h1>
                </div>
                <div className='py-3 card border-0 bg-transparent' data-aos="fade-up" data-aos-delay="400">
                    <div align="center" className='col-md-8 col-10 mx-auto fs-5 py-2'>
                        Elevate your freelancing game with devSemble, the ultimate platform for exciting projects and top-tier talent.
                        Join a vibrant community where opportunity meets talent, enabling both freelancers and businesses to thrive together.
                    </div>
                </div>
            </div>
            <div id='get-started-section' style={{ height: '50vh', overflow: 'hidden' }} className='py-5 d-flex justify-content-center align-items-center'>
                {userData === null ?
                    <div className='col-12 col-md-4 card border-0 bg-transparent rounded-lg p-4'>
                        <div className='text-center py-3 fs-3'>
                            Get Started Today!
                        </div>
                        <TextField
                            required={true}
                            className='m-0'
                            error={errorMessage === "" ? false : true}
                            type='email'
                            value={email}
                            label={errorMessage === '' ? "Email" : errorMessage}
                            onChange={handleChange('email')}
                            variant="outlined" />
                        <button
                            className='btn btn-primary fw-bold rounded-pill my-3'
                            onClick={() => {
                                handleSubmit(email)
                            }}>Get Started</button>
                    </div> : <div align='center'>
                        <h1>User Exist</h1>
                        {userData?.user.isReviewerVerified === false && userData?.user.isReviewer === true ?
                            <Link to="/reviewer-verification">
                                <button className='btn btn-secondary fw-bold'>
                                    Complete Your Reviewer Verification
                                </button>
                            </Link> : null}
                        {userData?.user.isAdvancedAccount === true ?
                            <Link to="/advanced-dashboard">
                                <button className='btn btn-secondary fw-bold'>
                                    Advanced Dashboard
                                </button>
                            </Link> : null}
                        {userData?.user.isReviewerVerified === true && userData.user.isReviewer === true ?
                            <Link to="/review-lists">
                                <button className='btn btn-secondary fw-bold'>
                                    Review Dashboard
                                </button>
                            </Link> : null}
                        {userData?.user.isReviewer === false && userData.user.isAdvancedAccount === false ?
                            <Link to="/dashboard">
                                <button className='btn btn-secondary fw-bold'>
                                    Dashboard
                                </button>
                            </Link> : null}
                        <button className='btn btn-danger fw-bold'
                            onClick={() => onClickLogout()}
                        >Log-Out</button>
                    </div>}
            </div>
            <div className='pt-5'>
                <Footer />
            </div>
        </>
    )
}

export default LanderPage