import { Link, useNavigate, useLocation } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import { registerUser } from '../actions/authActions';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import { styled } from '@mui/system';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Chip from '@mui/material/Chip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#0275d8',
    },
    '& label': {
        color: "#0275d8"
    },
    '& input': {
        color: "black"
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#0275d8',
        color: "black"
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#0275d8',
        },
        '&:hover fieldset': {
            borderColor: '#0275d8',
            color: "black"
        },
        '&.Mui-focused fieldset': {
            borderColor: '#0275d8',
            color: "black"
        },
    },
});

const LanderPage = () => {

    const [values, setValues] = useState({
        firstName: '',
        middleName: '',
        lastName: '',
        field: 'Student / Freelancer',
        password: '',
        error: '',
        errorMessage: ''
    })

    const testData = [
        'Student / Freelancer',
        'Reviewer',
        'Project host'
    ]

    const { state } = useLocation();

    const navigate = useNavigate()

    // var passwordRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;

    const { firstName, middleName, field, lastName, password, error, errorMessage } = values

    const handleSubmit = (firstName, field, middleName, lastName, password) => {
        if (password === '' || firstName === '' || field === '' || middleName === '' || lastName === '') {
            return setValues({ ...values, error: 'fieldErr', errorMessage: "All fields required!!" })
        } else {
            if (password.length <= 7) {
                return setValues({ ...values, error: 'passwordErr', errorMessage: "Password must be 8 characters" })
            }
            let data = {
                firstName: firstName,
                middleName: middleName,
                lastName: lastName,
                field: field,
                sessionId: state.sessionId,
                password: password
            }
            registerUser(data).then(res => {
                if (res.success.userExist === true) {
                    navigate('/login', { state: { sessionId: res.success._id } })
                }
            })
        }
    }

    const handleChange = name => event => {
        setValues({ ...values, error: '', errorMessage: '', [name]: event.target.value });
    };

    return (
        <div className='vh-100 d-flex justify-content-center align-items-center'>
            <div className='col-12 col-md-6 card bg-transparent rounded-lg p-4' data-aos="fade-left">
                <a href='/#get-started-section' className="" style={{ textDecoration: 'none' }}>
                    <button className="btn fw-bold btn-warning rounded-pill">
                        <FontAwesomeIcon size="1x" icon={faArrowLeft} /><span className="ps-lg-3">Back</span>
                    </button>
                </a>
                <h1>Register</h1>
                <CssTextField
                    className='my-2'
                    error={errorMessage === "" || error !== "fieldErr" ? false : true}
                    id="outlined-basic"
                    type='text'
                    label={errorMessage === '' || error !== "fieldErr" ? "First Name" : errorMessage}
                    onChange={handleChange('firstName')}
                    variant="outlined" />
                <CssTextField
                    className='my-2'
                    error={errorMessage === "" || error !== "fieldErr" ? false : true}
                    id="outlined-basic"
                    type='text'
                    label={errorMessage === '' || error !== "fieldErr" ? "Middle Name" : errorMessage}
                    onChange={handleChange('middleName')}
                    variant="outlined" />
                <CssTextField
                    className='my-2'
                    error={errorMessage === "" || error !== "fieldErr" ? false : true}
                    id="outlined-basic"
                    type='text'
                    label={errorMessage === '' || error !== "fieldErr" ? "Last Name" : errorMessage}
                    onChange={handleChange('lastName')}
                    variant="outlined" />
                <CssTextField
                    className='my-2'
                    error={errorMessage === "" || error !== "passwordErr" ? false : true}
                    id="outlined-basic"
                    type='password'
                    value={password}
                    label={errorMessage === '' || error !== "passwordErr" ? "Password" : errorMessage}
                    onChange={handleChange('password')}
                    variant="outlined" />
                <div className='py-3'>
                    {errorMessage === '' || error !== "fieldErr" ?
                        <InputLabel id="demo-multiple-chip-label" style={{ color: "#0275d8" }}>Select type of user</InputLabel>
                        :
                        <InputLabel id="demo-multiple-chip-label" style={{ color: "red" }}>{errorMessage}</InputLabel>
                    }
                    <Select
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        style={{ width: '100%', color: "black" }}
                        value={field}
                        defaultValue='Student / Freelancer'
                        onChange={handleChange('field')}
                        input={<OutlinedInput id="select-multiple-chip" label="" />}
                    >
                        {testData.map((name) => (
                            <MenuItem key={name} value={name}>
                                {name}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
                <button
                    className='btn btn-outline-primary my-3'
                    onClick={() => {
                        handleSubmit(firstName, field, middleName, lastName, password)
                    }}>Get Started</button>
                {/* <Link to="/login">Login</Link>
                <Link to="/register">Register</Link> */}
            </div>
        </div>
    )
}

export default LanderPage