import { useNavigate, Outlet, Link } from "react-router-dom";
import { useAuthStore } from '../../store/store';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import InputLabel from '@mui/material/InputLabel';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { Collapse } from '@mui/material';
import dayjs from 'dayjs';
import { submitReviewerProfile } from '../../actions/userProfileActions';
import AuthNavbar from "../AuthNavbar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import CircularProgress from '@mui/material/CircularProgress';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

const ReviewerVerification = () => {

    const data = useAuthStore(state => state.user)
    // const updateLocalUser = useAuthStore(state => state.updateUserProfileStatus)
    const setStateUser = useAuthStore(state => state.setUser)
    const navigate = useNavigate()

    const [isUpdate, setIsUpdate] = useState(false)
    const [values, setValues] = useState({
        text: '',
        errorMessage: '',
        error: ''
    })

    const [workDisplay, setWorkDisplay] = useState(false)
    const [workFormDisplay, setWorkFormDisplay] = useState(true)
    const [eduDisplay, setEduDisplay] = useState(false)
    const [eduFormDisplay, setEduFormDisplay] = useState(false)
    const [otherLinksForm, setOtherLinkForm] = useState(false)
    const [displayOtherLink, setDisplayOtherLink] = useState(false)
    const [displayProfile, setDisplayProfile] = useState(false)
    const [displayViewData, setDisplayViewData] = useState(false)
    const [verifiedSuccessStack, setVerifiedSuccessStack] = useState(false)
    const [verifiedSuccessGit, setVerifiedSuccessGit] = useState(false)
    const [loadingStack, setLoadingStack] = useState(false)
    const [loadingGit, setLoadingGit] = useState(false)

    const [wvd, setwvd] = useState(false)
    const [evd, setevd] = useState(false)
    const [lvd, setlvd] = useState(false)

    const [index, setIndex] = useState(null)

    const [stackUserName, setStackUserName] = useState('')
    const [gitUserName, setGitUserName] = useState('')

    const [work, setWork] = useState({
        title: '',
        description: '',
        startDate: '',
        endDate: '',
        workLink: '',
        currentlyPursuing: '',
        techValue: '',
        errorMessageWork: '',
        errorWork: ''
    })
    const [techUsed, setTechUsed] = useState([])

    const [education, setEducation] = useState({
        eduTitle: '',
        eduDescription: '',
        eduStartDate: '',
        eduEndDate: '',
        currentlyPursuingEdu: '',
        eduErrorMessageWork: '',
        eduErrorWork: ''
    })

    const [linkData, setLinkData] = useState({
        githubProfile: '',
        stackOverFlowProfile: '',
        linkTitle: '',
        linkHref: '',
        linkError: '',
        linkErrorMessage: '',
    })

    const [workExprience, setWorkExprience] = useState([])
    const [eduExprience, setEduExprience] = useState([])
    const [otherLinks, setOtherLink] = useState([])

    const { title, description, startDate, endDate, workLink, techValue, errorWork, errorMessageWork, currentlyPursuing } = work

    const { eduTitle, eduDescription, eduStartDate, eduEndDate, eduErrorMessage, eduError, currentlyPursuingEdu } = education

    const { githubProfile, stackOverFlowProfile, linkTitle, linkHref, linkError, linkErrorMessage } = linkData

    const { text, error, errorMessage } = values

    const [startDateValue, setStartDateValue] = useState(dayjs(new Date()));
    const [endDateValue, setEndDateValue] = useState(dayjs(new Date()));

    const handleChangeStartDate = (newValue) => {
        // setWork({ ...work, errorWork: '', errorMessageWork: '', startDate: dayjs(newValue).format('MM/DD/YY') });
        setStartDateValue(dayjs(newValue).format('MM/DD/YY'))
    };

    const handleChangeEndDate = (newValue) => {
        // setWork({ ...work, errorWork: '', errorMessageWork: '', endDate: dayjs(newValue).format('MM/DD/YY') });
        setEndDateValue(dayjs(newValue).format('MM/DD/YY'))
    };
    const handleChange = name => event => {
        setValues({ ...values, error: '', errorMessage: '', [name]: event.target.value });
    };

    const handleChangeWork = name => event => {
        setWork({ ...work, errorWork: '', errorMessageWork: '', [name]: event.target.value });
    };

    const handleChangeEdu = name => event => {
        setEducation({ ...education, eduError: '', eduErrorMessage: '', [name]: event.target.value });
    };

    const handleChangeLink = name => event => {
        setLinkData({ ...linkData, linkError: '', linkErrorMessage: '', [name]: event.target.value })
        setLoadingStack(false)
        setLoadingGit(false)
    }


    const handleCurrentlyPursuing = (event) => {
        // console.log(event.target.value)
        setWork({ ...work, currentlyPursuing: event.target.value, endDate: "Currently Working" })
    }

    const handleCurrentlyPursuingEdu = (event) => {
        // console.log(event.target.value)
        setEducation({ ...education, currentlyPursuingEdu: event.target.value, endDate: "Currently Working" })
    }

    const onAppendEduArray = (newInput) => {
        if (newInput.startDate === dayjs(new Date()).format("MM/DD/YY") || newInput.endDate === dayjs(new Date()).format("MM/DD/YY")) {
            setEducation({ ...education, eduError: 'date', eduErrorMessage: `Today's date cannot be used` })
        } else {
            setEduExprience(eduExprience => [...eduExprience, newInput])
            setEduDisplay(true)
            // setStartDateValue(dayjs(new Date()))
            // setEndDateValue(dayjs(new Date()))
            setEducation({
                eduTitle: 'test',
                eduDescription: 'test',
                eduStartDate: '',
                eduEndDate: '',
                eduErrorMessageWork: '',
                eduErrorWork: ''
            })
        }
    }

    const updateWorkTech = (data) => {
        setTechUsed(techUsed => [...techUsed, data])
    }
    const handleDeleteTech = (ele) => {
        setTechUsed(techUsed.filter(e => e !== ele))
    }

    const onAppendWorkArray = (newInput) => {
        if (newInput.startDate === dayjs(new Date()).format("MM/DD/YY") || newInput.endDate === dayjs(new Date()).format("MM/DD/YY")) {
            setWork({ ...work, errorWork: 'date', errorMessageWork: `Today's date cannot be used` })
        } else {
            setWorkExprience(workExprience => [...workExprience, newInput])
            setWorkDisplay(true)
            // setStartDateValue(dayjs(new Date()))
            // setEndDateValue(dayjs(new Date()))
            setWork({
                title: 'test',
                description: 'test',
                startDate: '',
                endDate: '',
                workLink: 'somelink',
                techValue: '',
                errorMessageWork: '',
                errorWork: ''
            })
            // setTechUsed([])
        }
    }

    const onAppendLinkArray = (newInput) => {
        setOtherLink(otherLinks => [...otherLinks, newInput])
        setDisplayOtherLink(true)
    }

    const deleteWork = (i) => {
        setWorkExprience(workExprience => {
            return workExprience.filter((_, index) => index !== i)
        })
    }
    const deleteEdu = (i) => {
        setEduExprience(eduExprience => {
            return eduExprience.filter((_, index) => index !== i)
        })
    }
    const deleteLink = (i) => {
        setOtherLink(otherLinks => {
            return otherLinks.filter((_, index) => index !== i)
        })
    }
    const deleteTech = (i) => {
        setWork({ ...work, techUsed: techUsed.filter(e => e !== i) })
    }

    const updateWork = (w) => {
        setWork(w)
        setTechUsed(w.techUsed)
        setStartDateValue(w.startDate)
        setEndDateValue(w.endDate)
    }

    const updateEdu = (w) => {
        setEducation(w)
        setStartDateValue(w.startDate)
        setEndDateValue(w.endDate)
    }

    const updateLink = (w) => {
        setLinkData(w)
    }

    const onUpdateWorkArray = (index, newInput) => {
        console.log(index, newInput)
        let dummyArray = [...workExprience]
        dummyArray[index] = newInput
        setWorkExprience(dummyArray)
        setTechUsed([])
        setIsUpdate(false)
    }

    const onUpdateEduArray = (index, newInput) => {
        let dummyArray = [...eduExprience]
        dummyArray[index] = newInput
        setEduExprience(dummyArray)
        setIsUpdate(false)
    }


    const onUpdateLinkArray = (index, newInput) => {
        console.log(index)
        let dummyArray = [...otherLinks]
        dummyArray[index] = newInput
        setOtherLink(dummyArray)
        setIsUpdate(false)
    }

    const handleSubmitProfile = (profileData) => {
        if (window.confirm('You need to will be logged out. Please login again!!')) {
            submitReviewerProfile(profileData).then(res => {
                if (res.success) {
                    navigate('/')
                    setStateUser(null)
                    localStorage.removeItem('user')
                } else {
                    console.log(res)
                }
            }).catch(err => {
                console.log(err)
            })
        } else {
            console.log('Thing was not saved to the database.');
        }
    }

    const onClickLogout = () => {
        navigate('/')
        setStateUser(null)
        localStorage.removeItem('user')
    }

    const verifyAccountStack = () => {
        setLoadingStack(true)
        fetch(`https://api.stackexchange.com/2.3/users/${stackOverFlowProfile}?site=stackoverflow`).then(res => {
            res.json().then(data => {
                if (data.items === undefined) {
                    setStackUserName('')
                    setVerifiedSuccessStack(false)
                    setLoadingStack(false)
                } else {
                    setStackUserName(data.items[0].display_name)
                    setVerifiedSuccessStack(true)
                    setLoadingStack(false)
                }
            })
        }).catch(err => {
            setStackUserName('')
        })
    }

    const verifyAccountGit = () => {
        setLoadingGit(true)
        fetch(`https://api.github.com/users/${githubProfile}`).then(response => {
            response.json().then(data => {
                if (data.message === 'Not Found') {
                    setGitUserName('')
                    setVerifiedSuccessGit(false)
                    setLoadingGit(false)
                } else {
                    setGitUserName(data.name)
                    setVerifiedSuccessGit(true)
                    setLoadingGit(false)
                }
            })
        })
    }

    return (
        <AuthNavbar>
            <div className=''>
                {data?.profileStatus === false ? <div style={{ backgroundColor: "wihtesmoke" }} className="card border-0 my-md-5 my-3 mx-md-5 mx-3">
                    <h3 align="center" className='text-danger'>!{data.message} for verifying as 'Reviewer'</h3>
                    {displayViewData === false ? <div className='row'>
                        {workFormDisplay === true ?
                            <div className='col-10 col-md-6 p-3 mx-auto'>
                                <h3 className="text-dark">Work Exprience Form</h3>
                                <div className=''>
                                    <TextField
                                        style={{ width: '100%', textTransform: 'capitalize !important' }}
                                        className='my-2'
                                        error={errorMessage === "" ? false : true}
                                        id="outlined-basic"
                                        value={title}
                                        label={errorMessage === '' ? "Title" : errorMessage}
                                        onChange={handleChangeWork('title')}
                                        variant="outlined" />
                                </div>
                                <div className=''>
                                    <TextField
                                        style={{ width: '100%' }}
                                        className='my-2'
                                        error={errorMessage === "" ? false : true}
                                        id="outlined-basic"
                                        multiline
                                        maxRows={4}
                                        value={description}
                                        label={errorMessage === '' ? "Description" : errorMessage}
                                        onChange={handleChangeWork('description')}
                                        variant="outlined" />
                                </div>
                                <div>
                                    <RadioGroup
                                        value={currentlyPursuing}
                                        onChange={handleCurrentlyPursuing}
                                    >
                                        <div className="d-flex flex-wrap justify-content-center align-items-center">
                                            <div className="col-md-5 col-5">
                                                <FormControlLabel className="text-dark" value={true} control={<Radio />} label="Currently Working" />
                                            </div>
                                            <div className="col-md-5 col-5">
                                                <FormControlLabel className="text-dark" value={false} control={<Radio />} label="Currently Not Working" />
                                            </div>
                                        </div>
                                    </RadioGroup>
                                </div>
                                <div align="center">
                                    <InputLabel className='text-danger'>{errorWork === 'date' ? errorMessageWork : null}</InputLabel>
                                    <InputLabel className='py-3'>Format: MM/DD/YY</InputLabel>
                                    <div className='d-flex justify-content-around'>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <MobileDatePicker
                                                label="Start Date"
                                                inputFormat="MM/DD/YYYY"
                                                maxDate={new Date()}
                                                value={startDateValue}
                                                onChange={handleChangeStartDate}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                            {currentlyPursuing === 'true' ? null :
                                                <MobileDatePicker
                                                    label="End Date"
                                                    inputFormat="MM/DD/YYYY"
                                                    minDate={startDateValue}
                                                    maxDate={new Date()}
                                                    value={endDateValue}
                                                    onChange={handleChangeEndDate}
                                                    renderInput={(params) => <TextField  {...params} />}
                                                />
                                            }
                                        </LocalizationProvider>
                                    </div>
                                </div>
                                <div className=''>
                                    <TextField
                                        style={{ width: '100%', textTransform: 'capitalize !important' }}
                                        className='my-2'
                                        error={errorMessage === "" ? false : true}
                                        id="outlined-basic"
                                        value={workLink}
                                        label={errorMessage === '' ? "Add link related if available" : errorMessage}
                                        onChange={handleChangeWork('workLink')}
                                        variant="outlined" />
                                </div>
                                <div className="card bg-transparent border-primary p-2">
                                    <div className="d-flex justify-content-center align-item-center">
                                        <div className="d-flex justify-content-between align-item-center row px-0 col-12">
                                            <div className="col-12 col-md-8 px-0">
                                                <TextField
                                                    className='my-2 col-12'
                                                    error={errorMessage === "" ? false : true}
                                                    id="custom-css-outlined-input"
                                                    color={error ? "error" : ""}
                                                    value={techValue}
                                                    label={errorMessage === '' ? "Tech Used" : errorMessage}
                                                    onChange={handleChangeWork('techValue')}
                                                    variant="outlined" />
                                            </div>
                                            <button onClick={() => updateWorkTech(techValue)} className="col-12 col-md-3 btn my-3 btn-primary rounded-pill">
                                                Add tech
                                            </button>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-around">
                                        <div className="col-12 card bg-transparent border-primary">
                                            <div className="col-12 d-flex flex-wrap ">
                                                {techUsed.map((p, i) => (
                                                    <div key={i} className="bg-primary rounded-pill p-1 px-2 m-2">
                                                        <span className="px-2">{p}</span>
                                                        <button className="btn btn-sm rounded-circle btn-danger" onClick={() => handleDeleteTech(p)}>
                                                            <FontAwesomeIcon size="1x" icon={faTrash} /></button>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div align="center">
                                    {isUpdate ?
                                        <button className='col-10 my-3 btn btn-warning'
                                            onClick={() => onUpdateWorkArray(index, {
                                                title,
                                                description,
                                                workLink,
                                                startDate: `${dayjs(startDateValue).format('MM/DD/YY')}`,
                                                endDate: `${currentlyPursuing === 'true' ? "Currently Working" : `${dayjs(endDateValue).format('MM/DD/YY')}`}`,
                                                techUsed
                                            })}>
                                            Update
                                        </button> :
                                        <div className='row justify-content-between'>
                                            <button className='col-12 col-lg-7 m-1 rounded-pill btn btn-primary'
                                                onClick={() => onAppendWorkArray({
                                                    title,
                                                    description,
                                                    workLink,
                                                    startDate: `${dayjs(startDateValue).format('MM/DD/YY')}`,
                                                    endDate: `${currentlyPursuing === 'true' ? "Currently Working" : `${dayjs(endDateValue).format('MM/DD/YY')}`}`,
                                                    techUsed
                                                })}>
                                                Add Work Exprience
                                            </button>
                                            {workExprience.length === 0 ?
                                                <button className='col-12 col-lg-3 m-1 rounded-pill btn btn-outline-secondary'
                                                    onClick={() => {
                                                        setWorkFormDisplay(false)
                                                        setEduFormDisplay(true)
                                                        setWorkDisplay(false)
                                                    }}>Skip</button> :
                                                <button className='col-12 col-lg-3 m-1 rounded-pill btn btn-outline-secondary'
                                                    onClick={() => {
                                                        setWorkFormDisplay(false)
                                                        setEduFormDisplay(true)
                                                        setWorkDisplay(false)
                                                    }}>Next</button>}</div>}
                                </div>
                            </div> : null}

                        {/* Education form */}

                        {eduFormDisplay === true ?
                            <div className='col-10 col-md-6 p-3 mx-auto'>
                                <h3 className="text-dark">Education Form</h3>
                                <div className=''>
                                    <TextField
                                        style={{ width: '100%', textTransform: 'capitalize !important' }}
                                        className='my-2'
                                        error={errorMessage === "" ? false : true}
                                        id="outlined-basic"
                                        value={eduTitle}
                                        label={errorMessage === '' ? "Title" : errorMessage}
                                        onChange={handleChangeEdu('eduTitle')}
                                        variant="outlined" />
                                </div>
                                <div className=''>
                                    <TextField
                                        style={{ width: '100%' }}
                                        className='my-2'
                                        error={errorMessage === "" ? false : true}
                                        id="outlined-basic"
                                        multiline
                                        maxRows={4}
                                        value={eduDescription}
                                        label={errorMessage === '' ? "Description" : errorMessage}
                                        onChange={handleChangeEdu('eduDescription')}
                                        variant="outlined" />
                                </div>
                                <div>
                                    <RadioGroup
                                        value={currentlyPursuingEdu}
                                        onChange={handleCurrentlyPursuingEdu}
                                    >
                                        <div className="d-flex flex-wrap justify-content-center align-items-center">
                                            <div className="col-md-5 col-5">
                                                <FormControlLabel className="text-dark" value={true} control={<Radio />} label="Currently Pursuing" />
                                            </div>
                                            <div className="col-md-5 col-5">
                                                <FormControlLabel className="text-dark" value={false} control={<Radio />} label="Currently Not Pursuing" />
                                            </div>
                                        </div>
                                    </RadioGroup>
                                </div>
                                <div align="center">
                                    <InputLabel className='text-danger'>{errorWork === 'date' ? errorMessageWork : null}</InputLabel>
                                    <InputLabel className='py-3'>Format: MM/DD/YY</InputLabel>
                                    <div className='d-flex justify-content-around'>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <MobileDatePicker
                                                label="Start Date"
                                                inputFormat="MM/DD/YYYY"
                                                maxDate={new Date()}
                                                value={startDateValue}
                                                onChange={handleChangeStartDate}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                            {currentlyPursuingEdu === 'true' ? null :
                                                <MobileDatePicker
                                                    label="End Date"
                                                    inputFormat="MM/DD/YYYY"
                                                    minDate={startDateValue}
                                                    maxDate={new Date()}
                                                    value={endDateValue}
                                                    onChange={handleChangeEndDate}
                                                    renderInput={(params) => <TextField  {...params} />}
                                                />
                                            }
                                        </LocalizationProvider>
                                    </div>
                                </div>
                                <div align="center" className='py-3'>
                                    {isUpdate ?
                                        <button className='col-10 my-3 btn btn-warning'
                                            onClick={() => onUpdateEduArray(index, {
                                                eduTitle,
                                                eduDescription,
                                                eduStartDate: `${dayjs(startDateValue).format('MM/DD/YY')}`,
                                                eduEndDate: `${currentlyPursuingEdu === 'true' ? "Currently Pursuing" : `${dayjs(startDateValue).format('MM/DD/YY')}`}`
                                            })}>
                                            Update
                                        </button> :
                                        <div className='row justify-content-between'>
                                            <button className='order-lg-2 order-1 col-12 col-lg-5 my-1 rounded-pill btn btn-primary'
                                                onClick={() => onAppendEduArray({
                                                    eduTitle,
                                                    eduDescription,
                                                    eduStartDate: `${dayjs(startDateValue).format('MM/DD/YY')}`,
                                                    eduEndDate: `${currentlyPursuingEdu === 'true' ? "Currently Pursuing" : `${dayjs(startDateValue).format('MM/DD/YY')}`}`
                                                })}>
                                                Add Education
                                            </button>
                                            <button className='order-lg-1 order-3 col-12 col-lg-3 my-1 rounded-pill btn btn-outline-secondary'
                                                onClick={() => {
                                                    setWorkFormDisplay(true)
                                                    setOtherLinkForm(false)
                                                    setEduFormDisplay(false)
                                                }}>Back</button>
                                            {eduExprience.length === 0 ?
                                                <button className='order-lg-3 order-2 col-12 col-lg-3 my-1 rounded-pill btn btn-outline-secondary'
                                                    onClick={() => {
                                                        setWorkFormDisplay(false)
                                                        setEduFormDisplay(false)
                                                        setEduDisplay(false)
                                                        setDisplayViewData(true)
                                                    }}>Skip</button> :

                                                <button className='order-lg-3 order-2 col-12 col-lg-3 my-1 rounded-pill btn btn-outline-secondary'
                                                    onClick={() => {
                                                        setWorkFormDisplay(false)
                                                        setEduFormDisplay(false)
                                                        setEduDisplay(false)
                                                        setDisplayViewData(true)
                                                        // handleSubmitProfile({
                                                        //     userId: data.user._id,
                                                        //     workExprience: workExprience, 
                                                        //     educationExprience: eduExprience, 
                                                        //     otherLinks: otherLinks, 
                                                        //     githubProfile: githubProfile, 
                                                        //     stackOverFlowProfile: stackOverFlowProfile
                                                        // })
                                                    }}>View Data</button>}</div>}
                                </div>
                            </div> : null}
                        {otherLinksForm === true ?
                            <div className='col-10 col-md-6 p-3 mx-auto'>
                                <div className='row d-flex justify-content-between'>
                                    <div className='col-12 col-lg-6'>
                                        <TextField
                                            style={{ width: '100%', textTransform: 'capitalize !important' }}
                                            className='my-2'
                                            error={errorMessage === "" ? false : true}
                                            id="outlined-basic"
                                            value={githubProfile}
                                            label={errorMessage === '' ? "GitHub Link" : errorMessage}
                                            onChange={handleChangeLink('githubProfile')}
                                            variant="outlined" />
                                        <div align="center">
                                            {loadingGit ? <CircularProgress /> : <div>{gitUserName === '' || gitUserName === null ? <span className="text-danger fs-5">Verification pending</span> :
                                                <span className="text-dark fs-5">UserName: {gitUserName}</span>}</div>}
                                        </div>
                                    </div>
                                    <div className='col-12 col-lg-6'>
                                        <TextField
                                            style={{ width: '100%', textTransform: 'capitalize !important' }}
                                            className='my-2'
                                            error={errorMessage === "" ? false : true}
                                            id="outlined-basic"
                                            value={stackOverFlowProfile}
                                            label={errorMessage === '' ? "Provide the Id of Stack overflow" : errorMessage}
                                            onChange={handleChangeLink('stackOverFlowProfile')}
                                            variant="outlined" />
                                        <div align="center">
                                            {loadingStack ? <CircularProgress /> : <div>{stackUserName === '' ? <span className="text-danger fs-5">Verification pending</span> :
                                                <span className="text-dark fs-5">UserName: {stackUserName}</span>}</div>}
                                        </div>
                                    </div>
                                </div>
                                <div align="center" className="col-12">
                                    <button onClick={() => {
                                        verifyAccountStack()
                                        verifyAccountGit()
                                    }} className="col-10 btn btn-primary rounded-pill">
                                        Verify Accounts
                                    </button>
                                </div>
                                <div className='d-flex row py-2 justify-content-center align-items-center'>
                                    <div className='col'>
                                        <hr style={{ paddingTop: 1, paddingBottom: 2, color: 'black' }} />
                                    </div>
                                    <div align="center" className='col'>
                                        <p className='p-0 m-0 text-dark'>Add other links</p>
                                    </div>
                                    <div className='col'>
                                        <hr style={{ paddingTop: 1, paddingBottom: 2, color: 'black' }} />
                                    </div>
                                </div>
                                {/* <Collapse in={verifiedSuccessStack===true && verifiedSuccessGit===true}> */}
                                <div className=''>
                                    <TextField
                                        style={{ width: '100%', textTransform: 'capitalize !important' }}
                                        className='my-2'
                                        error={errorMessage === "" ? false : true}
                                        id="outlined-basic"
                                        value={linkTitle}
                                        label={errorMessage === '' ? "Title" : errorMessage}
                                        onChange={handleChangeLink('linkTitle')}
                                        variant="outlined" />
                                </div>
                                <div className=''>
                                    <TextField
                                        style={{ width: '100%' }}
                                        className='my-2'
                                        error={errorMessage === "" ? false : true}
                                        id="outlined-basic"
                                        value={linkHref}
                                        label={errorMessage === '' ? "Link" : errorMessage}
                                        onChange={handleChangeLink('linkHref')}
                                        variant="outlined" />
                                </div>
                                <div align="center" className='py-3'>
                                    {isUpdate ?
                                        <button className='col-10 my-3 btn btn-warning'
                                            onClick={() => onUpdateLinkArray(index, {
                                                linkTitle,
                                                linkHref
                                            })}>
                                            Update
                                        </button> :
                                        <div className='row justify-content-between'>
                                            <button className='order-lg-2 order-1 col-12 col-lg-5 my-1 rounded-pill btn btn-primary'
                                                onClick={() => onAppendLinkArray({
                                                    linkTitle,
                                                    linkHref
                                                })}>
                                                Add Link
                                            </button>
                                            <button className='order-lg-1 order-3 col-12 col-lg-3 my-1 rounded-pill btn btn-outline-secondary'
                                                onClick={() => {
                                                    setWorkFormDisplay(false)
                                                    setOtherLinkForm(false)
                                                    setEduFormDisplay(true)
                                                }}>Back</button>
                                            <button className='order-lg-3 order-2 col-12 col-lg-3 my-1 rounded-pill btn btn-outline-secondary'
                                                onClick={() => {
                                                    setDisplayViewData(true)
                                                    setEduFormDisplay(false)
                                                    setWorkFormDisplay(false)
                                                    setOtherLinkForm(false)
                                                    // handleSubmitProfile({
                                                    //     userId: data.user._id,
                                                    //     workExprience: workExprience, 
                                                    //     educationExprience: eduExprience, 
                                                    //     otherLinks: otherLinks, 
                                                    //     githubProfile: githubProfile, 
                                                    //     stackOverFlowProfile: stackOverFlowProfile
                                                    // })
                                                }}>View Data</button></div>}
                                </div>
                                {/* </Collapse> */}
                            </div> : null}
                        <div className='text-dark col-11 col-lg-4 px-0 mx-auto'>
                            <Collapse className='' in={workDisplay}>
                                <div align="center"><h3>Work exprience you added</h3></div>
                                <div className='border-start border-3 border-primary shadow-lg'>
                                    {workExprience.length !== 0 ? <div className='py-0'>{workExprience.map((w, i) =>
                                        <div className='container-fluid border-bottom border-3 border-primary my-0 p-2' key={i}>
                                            <span className='d-flex align-items-center'><h4>{w.title}</h4><span className='px-3'> {w.startDate} - {w.endDate}</span></span>
                                            <div style={{ height: '10vh', overflow: 'auto' }}><b className="">Description:</b> {w.description}</div><br />
                                            <div>Tech Used: {w.techUsed.map((t, i) => <span key={i} className='bg-primary rounded-pill mx-2 px-2 p-1'>{t}</span>)}</div>
                                            <hr className='bg-dark p-0 m-1' />
                                            <div className='d-flex justify-content-around py-2'>
                                                <button className='btn btn-warning p-1 px-3 rounded-pill'
                                                    onClick={() => {
                                                        setIsUpdate(true)
                                                        setIndex(i)
                                                        updateWork(w)
                                                    }}>Update</button>
                                                <button className='btn rounded-pill p-1 px-3 btn-outline-danger'
                                                    onClick={() => deleteWork(i)}>Delete</button>
                                            </div>
                                        </div>)}</div> : null}
                                </div>
                                <div align="center" className='py-3'>
                                    <button className='btn btn-dark rounded-pill'
                                        onClick={() => setWorkDisplay(false)}>
                                        Close
                                    </button>
                                </div>
                            </Collapse>
                            <Collapse className='' in={eduDisplay}>
                                <div align="center"><h3>Education you added</h3></div>
                                <div className='border-start border-3 border-primary shadow-lg'>
                                    {eduExprience.length !== 0 ? <div className='py-0'>{eduExprience.map((w, i) =>
                                        <div className='container-fluid border-bottom border-3 border-primary my-0 p-2' key={i}>
                                            <span className='d-flex align-items-center'><h4>{w.eduTitle}</h4><span className='px-3'> {w.eduStartDate} - {w.eduEndDate}</span></span>
                                            <div style={{ height: '5vh', overflow: 'auto' }}><b className="">Description:</b> {w.eduDescription}</div><br />
                                            <hr className='bg-dark p-0 m-1' />
                                            <div className='d-flex justify-content-around py-2'>
                                                <button className='btn btn-warning p-1 px-3 rounded-pill'
                                                    onClick={() => {
                                                        setIsUpdate(true)
                                                        setIndex(i)
                                                        updateEdu(w)
                                                    }}>Update</button>
                                                <button className='btn rounded-pill p-1 px-3 btn-outline-danger'
                                                    onClick={() => deleteEdu(i)}>Delete</button>
                                            </div>
                                        </div>)}</div> : null}
                                </div>
                                <div align="center" className='py-3'>
                                    <button className='btn btn-dark rounded-pill'
                                        onClick={() => setEduDisplay(false)}>
                                        Close
                                    </button>
                                </div>
                            </Collapse>
                            <Collapse className='' in={displayOtherLink}>
                                <div align="center"><h3>Other Links</h3></div>
                                <div className='border-start border-3 border-primary shadow-lg'>
                                    {otherLinks.length !== 0 ? <div className='py-0'>{otherLinks.map((w, i) =>
                                        <div className='container-fluid border-bottom border-3 border-primary my-0 p-2' key={i}>
                                            <span className='d-flex align-items-center'><h4>{w.linkTitle}</h4></span>
                                            <div style={{ height: '5vh', overflow: 'auto' }}><b className="">Link:</b> {w.linkHref}</div><br />
                                            <hr className='bg-dark p-0 m-1' />
                                            <div className='d-flex justify-content-around py-2'>
                                                <button className='btn btn-warning p-1 px-3 rounded-pill'
                                                    onClick={() => {
                                                        setIsUpdate(true)
                                                        setIndex(i)
                                                        updateLink(w)
                                                    }}>Update</button>
                                                <button className='btn rounded-pill p-1 px-3 btn-outline-danger'
                                                    onClick={() => deleteLink(i)}>Delete</button>
                                            </div>
                                        </div>)}</div> : null}
                                </div>
                                <div align="center" className='py-3'>
                                    <button className='btn btn-dark rounded-pill'
                                        onClick={() => setDisplayOtherLink(false)}>
                                        Close
                                    </button>
                                </div>
                            </Collapse>
                            <Collapse className='d-lg-block d-none' in={!workDisplay && !eduDisplay && !displayOtherLink}>
                                <div align='center' className='py-2'>
                                    <h3>Profile you added</h3>
                                </div>
                                <div className='d-flex justify-content-around align-items-center'>
                                    <div className='px-2'>
                                        <button className='m-auto btn btn col-12 rounded-pill btn-outline-success'
                                            onClick={() => setWorkDisplay(true)}>
                                            {`Work - ${workExprience.length}`}
                                        </button>
                                    </div>
                                    <div className='px-2'>
                                        <button className='m-auto btn btn col-12 rounded-pill btn-outline-success'
                                            onClick={() => setEduDisplay(true)}>
                                            {`Education - ${eduExprience.length}`}
                                        </button>
                                    </div>
                                </div>
                            </Collapse>
                        </div>
                    </div> : <div className='text-dark'>
                        <div align="center" className='py-3'>
                            <button className='btn btn-dark rounded-pill'
                                onClick={() => {
                                    setWorkFormDisplay(true)
                                    setDisplayViewData(false)
                                }}>
                                Move back to forms
                            </button>
                            <button className='btn btn-dark rounded-pill'
                                onClick={() =>
                                    handleSubmitProfile({
                                        userId: data.user._id,
                                        workExprience: workExprience,
                                        educationExprience: eduExprience,
                                    })}>
                                Upload Data
                            </button>
                        </div>
                        <div className='row'>
                            <div className='col-lg p-3'>
                                <Collapse in={!wvd}>
                                    <button
                                        onClick={() => {
                                            setwvd(true)
                                            setevd(false)
                                            setlvd(false)
                                        }}
                                        className='btn col-12 rounded-pill btn-outline-success'>Open work exprience</button>
                                </Collapse>
                                <Collapse in={wvd}>
                                    <div align="center" className='d-flex justify-content-around align-items-center'><h3>Work exprience you added</h3>
                                        <button
                                            onClick={() => setwvd(false)}
                                            className='btn my-3 rounded-pill btn-outline-danger'>Close</button></div>
                                    <div className='border-start border-3 border-primary shadow' style={{ height: '50vh', overflowY: 'scroll' }}>
                                        {workExprience.length !== 0 ? <div className='py-0'>{workExprience.map((w, i) =>
                                            <div className='container-fluid border-bottom border-3 border-primary my-0 p-2' key={i}>
                                                <span className='d-flex align-items-center'><h4>{w.title}</h4><span className='px-3'> {w.startDate} - {w.endDate}</span></span>
                                                <div style={{ height: '10vh', overflowY: 'auto' }}><b className="">Description:</b> {w.description}</div><br />
                                                <div style={{ wordWrap: 'break-word' }}>Tech Used: {w.techUsed.map((t, i) => <span key={i} className='bg-primary rounded-pill mx-2 px-2 p-1' style={{ wordWrap: 'break-word' }}>{t}</span>)}</div>
                                                <hr className='bg-dark p-0 m-1' />
                                                <div className='d-flex justify-content-around py-2'>
                                                    <button className='btn btn-warning p-1 px-3 rounded-pill'
                                                        onClick={() => {
                                                            setWorkFormDisplay(true)
                                                            setWorkDisplay(true)
                                                            setIsUpdate(true)
                                                            setIndex(i)
                                                            updateWork(w)
                                                            setDisplayViewData(false)
                                                        }}>Update</button>
                                                    <button className='btn rounded-pill p-1 px-3 btn-outline-danger'
                                                        onClick={() => deleteWork(i)}>Delete</button>
                                                </div>
                                            </div>)}</div> : null}
                                    </div>
                                    {/* <button
                                onClick={() => setwvd(false)}
                                className='btn my-3 shadow-lg col-12 rounded-pill btn-outline-danger'>Close</button> */}
                                </Collapse>
                            </div>
                            <div className='d-lg-none d-block'>
                                <hr className='bg-dark' />
                            </div>
                            <div className='col-lg p-3'>
                                <Collapse in={!evd}>
                                    <button
                                        onClick={() => {
                                            setwvd(false)
                                            setevd(true)
                                            setlvd(false)
                                        }}
                                        className='btn col-12 rounded-pill btn-outline-success'>Open education exprience</button>
                                </Collapse>
                                <Collapse in={evd}>
                                    <div align="center" className='d-flex justify-content-around align-items-center'><h3>Education exprience you added</h3>
                                        <button
                                            onClick={() => setevd(false)}
                                            className='btn my-3 rounded-pill btn-outline-danger'>Close</button></div>
                                    <div className='border-start border-3 border-primary shadow' style={{ height: '50vh', overflowY: 'scroll' }}>
                                        {eduExprience.length !== 0 ? <div className='py-0'>{eduExprience.map((w, i) =>
                                            <div className='container-fluid border-bottom border-3 border-primary my-0 p-2' key={i}>
                                                <span className='d-flex align-items-center'><h4>{w.eduTitle}</h4><span className='px-3'> {w.eduStartDate} - {w.eduEndDate}</span></span>
                                                <div style={{ height: '5vh', overflow: 'auto' }}><b className="">Description:</b> {w.eduDescription}</div><br />
                                                <hr className='bg-dark p-0 m-1' />
                                                <div className='d-flex justify-content-around py-2'>
                                                    <button className='btn btn-warning p-1 px-3 rounded-pill'
                                                        onClick={() => {
                                                            setEduFormDisplay(true)
                                                            setEduDisplay(true)
                                                            setIsUpdate(true)
                                                            setIndex(i)
                                                            updateEdu(w)
                                                            setDisplayViewData(false)
                                                        }}>Update</button>
                                                    <button className='btn rounded-pill p-1 px-3 btn-outline-danger'
                                                        onClick={() => deleteEdu(i)}>Delete</button>
                                                </div>
                                            </div>)}</div> : null}
                                    </div>
                                </Collapse>
                            </div>
                            <div className='d-lg-none d-block'>
                                <hr className='bg-dark' />
                            </div>
                        </div>
                    </div>}</div> :
                    // this is the logged in section
                    <div className=" py-5">
                        <div>
                            <span className="fs-2">
                                DashBoard
                            </span>
                        </div>
                        <div className="row container-fluid">
                            <div className="col-12 col-md-6">
                                <div className="card bg-transparent p-2 rounded-lg border-primary">
                                    <div className="d-flex flex-wrap justify-content-between">
                                        <div className="py-1">
                                            {/* <Link to='my-profile' state={data.profile}> */}
                                            <button
                                                onClick={() => { displayProfile ? setDisplayProfile(false) : setDisplayProfile(true) }}
                                                className="btn btn-outline-primary rounded-pill">
                                                My Profile
                                            </button>
                                            {/* </Link> */}
                                        </div>
                                        <div className="py-1">
                                            <Link to='teams-joined' state={data.profile.joined}>
                                                <button className="btn btn-outline-primary rounded-pill">
                                                    Team Joined
                                                </button>
                                            </Link>
                                        </div>
                                        <div className="py-1">
                                            <Link to='team-requested' state={data.profile.pendingRequests}>
                                                <button className="btn btn-outline-primary rounded-pill">
                                                    Team Requested
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                    <Collapse in={displayProfile} >
                                        <div className="card my-2 bg-transparent rounded-lg border-primary p-2">
                                            <div>
                                                <span className="fs-3">
                                                    <strong>
                                                        {data.user.firstName} {data.user.lastName}
                                                    </strong>
                                                </span>
                                            </div>
                                            <hr />
                                            <div>
                                                <div className="d-flex justify-content-between">
                                                    <span className="fs-4">Your Profile</span>
                                                    <button className="btn btn-warning rounded-pill">
                                                        Update
                                                    </button>
                                                </div>
                                                <div className="py-3 p-2">
                                                    <div className='d-flex row'>
                                                        <div className='col-12 p-1'>
                                                            <Collapse in={!wvd}>
                                                                <button
                                                                    onClick={() => {
                                                                        setwvd(true)
                                                                        setevd(false)
                                                                        setlvd(false)
                                                                    }}
                                                                    className='btn col-12 rounded-pill btn-outline-success'>Open work exprience</button>
                                                            </Collapse>
                                                            <Collapse in={wvd}>
                                                                <div align="center" className='d-flex justify-content-around align-items-center'><h3>Work exprience you added</h3></div>
                                                                <div className='border-primary rounded-lg shadow'>
                                                                    {data.profile.workExprience.length !== 0 ? <div className='py-0'>{data.profile.workExprience.map((w, i) =>
                                                                        <div className='container-fluid border-primary my-0 p-2' key={i}>
                                                                            <span className='d-flex align-items-center'><h4>{w.title}</h4><span className='px-3'> {w.startDate} - {w.endDate}</span></span>
                                                                            <div><b className="">Description:</b> {w.description}</div><br />
                                                                            <div style={{ wordWrap: 'break-word' }}>Tech Used: {w.techUsed.map((t, i) => <span key={i} className='bg-primary rounded-pill mx-2 px-2 p-1' style={{ wordWrap: 'break-word' }}>{t}</span>)}</div>
                                                                            <hr />
                                                                        </div>)}</div> : null}
                                                                </div>
                                                            </Collapse>
                                                        </div>
                                                        <div className='d-lg-none d-block'>
                                                            <hr className='bg-dark' />
                                                        </div>
                                                        <div className='col-12 p-1'>
                                                            <Collapse in={!evd}>
                                                                <button
                                                                    onClick={() => {
                                                                        setwvd(false)
                                                                        setevd(true)
                                                                        setlvd(false)
                                                                    }}
                                                                    className='btn col-12 rounded-pill btn-outline-success'>Open Education exprience</button>
                                                            </Collapse>
                                                            <Collapse in={evd}>
                                                                <div align="center" className='d-flex justify-content-around align-items-center'><h3>Education exprience you added</h3></div>
                                                                <div className='shadow'>
                                                                    {data.profile.educationExprience.length !== 0 ? <div className='py-0'>{data.profile.educationExprience.map((w, i) =>
                                                                        <div className='container-fluid border-primary my-0 p-2' key={i}>
                                                                            <span className='d-flex align-items-center'><h4>{w.eduTitle}</h4><span className='px-3'> {w.eduStartDate} - {w.eduEndDate}</span></span>
                                                                            <div><b className="">Description:</b> {w.eduDescription}</div><br />
                                                                            <hr />
                                                                        </div>)}</div> : null}
                                                                </div>
                                                            </Collapse>
                                                        </div>
                                                        <div className='d-lg-none d-block'>
                                                            <hr className='bg-dark' />
                                                        </div>
                                                        <div className='col-12 p-1'>
                                                            <Collapse in={!lvd}>
                                                                <button
                                                                    onClick={() => {
                                                                        setwvd(false)
                                                                        setevd(false)
                                                                        setlvd(true)
                                                                    }}
                                                                    className='btn col-12 rounded-pill btn-outline-success'>Open Links</button>
                                                            </Collapse>
                                                            <Collapse in={lvd}>
                                                                <div align="center" className='d-flex justify-content-around align-items-center'><h3>Other links you added</h3></div>
                                                                <div className='shadow'>
                                                                    {data.profile.otherLinks.length !== 0 ? <div className='py-0'>{data.profile.otherLinks.map((w, i) =>
                                                                        <div className='container-fluid border-primary my-0 p-2' key={i}>
                                                                            <span className='d-flex align-items-center'><h4>{w.linkTitle}</h4></span>
                                                                            <div><b className="">Link:</b> {w.linkHref}</div>
                                                                            <hr />
                                                                        </div>)}</div> : null}
                                                                </div>
                                                            </Collapse>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Collapse>
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="card bg-transparent rounded-lg border-primary p-2">
                                    <Outlet />
                                </div>
                            </div>
                        </div>
                    </div>}
            </div>
        </AuthNavbar>
    )
}

export default ReviewerVerification

