import { useLocation } from "react-router-dom"
import AuthNavbar from "../AuthAccess/AuthNavbar"

const PublicDisplaySingleProject = () => {

    const {p} = useLocation().state

    return (
        <AuthNavbar>
            <div className="py-5">
                Single PROJECT
                {JSON.stringify(p)}
            </div>
        </AuthNavbar>
    )
}

export default PublicDisplaySingleProject