import { Link, useLocation } from "react-router-dom"
import { useOutletContext } from "react-router-dom"
import { AllowAdvancedAccount } from "../../manageRoutes/ProtectedRoutes"
const ProjectTasks = () => {

    const projectId = useLocation().state
    
    const [displayOutlet, setDisplayOutlet] = useOutletContext()

    return (
        <AllowAdvancedAccount>
            <div>
                ProjectTasks
                <Link to={`/advanced-dashboard/manage-projects/single-project/${projectId}` } >
                    <button onClick={() => setDisplayOutlet(false)} className="btn btn-warning">
                        Back
                    </button>
                </Link>
            </div>
        </AllowAdvancedAccount>
    )
}

export default ProjectTasks