import { useState } from "react"
import AuthNavbar from "../AuthAccess/AuthNavbar"
import { Collapse } from "@mui/material"
import addWorkExprience from '../authFlow/assets/howItWorks/addWorkExprience.png'

const HowItWorks = () => {

    const [displayUser, setDisplayUser] = useState(false)
    const [displayReviewer, setDisplayReviewer] = useState(false)
    const [displayProject, setDisplayProject] = useState(false)
    const [displayButton, setDisplayButton] = useState(true)

    return (
        <AuthNavbar>
        <div className="py-5">
            <div className="display-1 text-center">
                How it works
            </div>
            <Collapse in={displayButton}>
                <div className="text-center py-3 d-flex flex-wrap justify-content-around align-items-center">
                    <div className="col-md-4 col-10 py-3">
                        <button onClick={() => {
                            setDisplayButton(false)
                            setDisplayProject(false)
                            setDisplayReviewer(false)
                            setDisplayUser(true)
                        }} className="btn btn-success col-10">
                            For User
                        </button>
                    </div>
                    <div className="col-md-4 col-10 py-3">
                        <button onClick={() => {
                            setDisplayButton(false)
                            setDisplayProject(false)
                            setDisplayReviewer(true)
                            setDisplayUser(false)
                        }} className="btn btn-success col-10">
                            For Reviewer
                        </button>
                    </div>
                    <div className="col-md-4 col-10 py-3">
                        <button onClick={() => {
                            setDisplayButton(false)
                            setDisplayProject(true)
                            setDisplayReviewer(false)
                            setDisplayUser(false)
                        }} className="btn btn-success col-10">
                            For Companies and Project Leads
                        </button>
                    </div>
                </div>
            </Collapse>
            <div>
                <Collapse in={displayUser}>
                    <div className="card bg-transparent py-5">
                        <div align="right">
                            <button onClick={() => {
                                setDisplayUser(false)
                                setDisplayButton(true)
                            }} className="col-md-2 col  btn btn-outline-danger">
                                Close
                            </button>
                        </div>
                        <div align="center" className="display-4">
                            For Users
                        </div>
                        <div className="d-flex justify-content-center">
                            <div className="bg-transparent p-2 border-0 col-md-8 col-12">
                                <div className="">
                                    <img  className="img-fluid" src={addWorkExprience} alt="Add Your Work Exprience" style={{boxShadow: '0 0 1rem #0275d8'}} />
                                </div>
                                <div className="py-3 fs-4">
                                    After Login in into devSemble Users will be redirected to this form
                                </div>
                            </div>
                        </div>
                    </div>
                </Collapse>
                <Collapse in={displayReviewer}>
                    <div className="card bg-transparent py-5">
                        <div align="right">
                            <button onClick={() => {
                                setDisplayReviewer(false)
                                setDisplayButton(true)
                            }} className="col-md-2 col  btn btn-outline-danger">
                                Close
                            </button>
                        </div>
                        <div className="mx-auto">
                            <div>
                                
                            </div>
                        </div>
                    </div>

                </Collapse>
                <Collapse in={displayProject}>
                    <div className="card bg-transparent py-5">
                        <div align="right">
                            <button onClick={() => {
                                setDisplayProject(false)
                                setDisplayButton(true)
                            }} className="col-md-2 col  btn btn-outline-danger">
                                Close
                            </button>
                        </div>
                        <div className="mx-auto">
                            <div>
                                
                            </div>
                        </div>
                    </div>

                </Collapse>
            </div>
        </div>
        </AuthNavbar>
    )
}

export default HowItWorks