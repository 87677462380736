import { TextField } from '@mui/material';
import contactUs from '../authFlow/assets/contact_us_page.png'
import Navbar from '../authFlow/Navbar';
import { useAuthStore } from '../store/store';
import { fetchEmail } from '../actions/authActions';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import Footer from './Footer';

const ContactUs = () => {

    const userData = useAuthStore(state => state.user)
    const setStateUser = useAuthStore(state => state.setUser)
    const navigate = useNavigate()

    const [values, setValues] = useState({
        email: '',
        error: '',
        errorMessage: ''
    })

    const { email, errorMessage } = values

    const emailRegex = /\S+@\S+\.\S+/;

    const handleChange = name => event => {
        setValues({ ...values, error: '', errorMessage: '', [name]: event.target.value });
    };

    const handleSubmit = (email) => {
        if (emailRegex.test(email)) {
            fetchEmail({ email: email }).then(res => {
                if (res.userExist === false) {
                    navigate('/register', { state: { sessionId: res.sessionId } })
                }
                if (res.userExist === true) {
                    navigate('/login', { state: { sessionId: res.sessionId } })
                }
            })
        } else {
            setValues({ ...values, errorMessage: "Please enter a valid Email" })
        }
    }

    const onClickLogout = () => {
        setStateUser(null)
        localStorage.removeItem('user')
    }

    return (
        <>
            <div className='py-2 sticky-top'>
                <Navbar />
            </div>
            <div className="container-fluid d-flex flex-wrap justify-content-center py-5">
                <div className="col-11">
                    <div className='fs-1 d-lg-block d-none pb-3 fw-bold text-center' data-aos="fade-up" data-aos-delay="500">
                        Connect Us
                    </div>
                    <div className="d-flex flex-wrap justify-content-center align-items-center">
                        <div className="col-lg-5 px-md-5">
                            <img src={contactUs} className="img-fluid" alt="devSemble Logo" data-aos="fade-right" data-aos-delay="500" />
                            <div className='fs-1 d-lg-none d-block pb-3 fw-bold text-center' data-aos="fade-up" data-aos-delay="00">
                                Connect Us
                            </div>
                        </div>
                        <div className="col-lg-6 px-md-5">
                            <div className="fs-3">
                                <div className='py-2' data-aos="fade-up" data-aos-delay="200">
                                    We’re here to help! If you have any questions, need support, or want to give feedback, don’t hesitate to reach out.
                                </div>
                                <div className='py-2' data-aos="fade-up" data-aos-delay="300">
                                    Contact us via email at <a className='fw-bold' href="mailto:contact.devsemble@gmail.com" target='_blank'>contact.devsemble@gmail.com</a> or call us at <a className='fw-bold' href="tel:8108127266">+91 810812766.</a>
                                </div>
                                <div className='py-2' data-aos="fade-up" data-aos-delay="300">
                                Or Visit our Office at <span className='fw-bold'>Somaiya Vidyavihar, Room No. 520, Bhaskarachraya Building, Vidyavihar, Mumbai, Maharashtra 400077</span>
                                </div>
                                <div className='py-2' data-aos="fade-up" data-aos-delay="400">
                                    We look forward to hearing from you.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='pt-5'>
                <Footer />
            </div>
        </>
    )
}

export default ContactUs