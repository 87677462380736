import { useEffect, useState } from "react"
import { getPublicProject } from "../actions/projectActions"
import AuthNavbar from "../AuthAccess/AuthNavbar"
import { Collapse } from "@mui/material"
import { Link } from "react-router-dom"


const PublicProjectDisplay = () => {

    const [displayProjects, setDisplayProjects] = useState([])

    const [error, setError] = useState({
        errorType: '',
        errorMessage: ''
    })

    const [displayUpdatedData, setDisplayUpdatedData] = useState(-1)

    const { errorMessage, errorType } = error

    useEffect(() => {
        getPublicProject().then(res => {
            if (res.status) {
                setDisplayProjects(res?.projects.reverse())
                setError({ ...error, errorType: "", errorMessage: '' })
            } else {
                setError({ ...error, errorType: "EMPTY_LIST", errorMessage: 'No Projects Found' })
            }
        }).catch(err => {
            console.log(err)
        })
    }, [])

    return (
        <AuthNavbar>
            <div className="">
                <div className="text-center pt-5">
                    <h2>Public Display Projects</h2>
                </div>
                {errorType === 'EMPTY_LIST' ? <div className="text-center text-danger py-5 fs-3">{errorMessage}</div> : null}
                <div className=" d-flex flex-wrap justify-content-center align-items-center">
                    <div className="col-md-8 col-12">
                        <div className="container-fluid">
                            {displayProjects.length === 0 ? null :
                                <div>
                                    {displayProjects.map((p, i) => (
                                        <div key={i} className="p-2">
                                            <div className="card p-2 bg-transparent border-primary">
                                                <div className="d-flex flex-wrap justify-content-between align-items-center">
                                                    <div>
                                                    <Link to={`/display-single-public-project/${p._id}`} className="" state={{p}}>
                                                        <span className="fs-4 fw-bold me-2">{p.projectTitle}</span>
                                                        </Link>
                                                    </div>
                                                    <div>
                                                        {p.projectTech.length !== 0 ? <div className="col-12 d-flex flex-wrap ">
                                                            {p.projectTech.map((t, i) => (
                                                                <div key={i} className="bg-primary rounded-pill px-2 me-2">
                                                                    <span className="">{t}</span>
                                                                </div>
                                                            ))}
                                                        </div> : null}
                                                    </div>
                                                </div>
                                                <div>
                                                    <span className="fs-5">{p.projectDescription}</span>
                                                </div>
                                                <hr className="my-2" />
                                                <div>
                                                    <Collapse in={displayUpdatedData === -1}>
                                                        <button onClick={() => setDisplayUpdatedData(i)} className="btn rounded-lg btn-sm btn-primary p-0">
                                                            <span className="p-2 fw-bold">View Team</span>
                                                        </button>
                                                    </Collapse>
                                                </div>
                                                <Collapse in={displayUpdatedData === i}>
                                                    <div className="">
                                                        <button onClick={() => setDisplayUpdatedData(-1)} className="btn rounded-lg btn-sm btn-danger p-0">
                                                            <span className="p-2 fw-bold">Close</span>
                                                        </button>
                                                        <div className="py-2">
                                                            {JSON.stringify(p?.projectTeam)}
                                                            {p?.projectTeam.length === 0 ? <div>No Team Members Added</div> : <div>
                                                                {p?.projectTeam?.map((t, i) => (
                                                                    <div className="" key={i}>
                                                                        <div className="">
                                                                            {t.firstName} {t.lastName}
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>}
                                                        </div>
                                                    </div>
                                                </Collapse>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </AuthNavbar>
    )
}

export default PublicProjectDisplay