import { useEffect, useState } from "react"
// import { useAuthStore } from '../store/store';
import { useParams } from "react-router-dom"
import { getSinglePostReviewUser } from "../../actions/reviewActions"

const SinglePostedReview = () => {

    // const data = useAuthStore(state => state.user)
    let {rId} = useParams()

    const [userInfo, setUserInfo] = useState({})

    useEffect(() => {
        getSinglePostReviewUser(rId).then(res => {
            setUserInfo(res)
        })
    }, [rId])

    return (
        <div>
            <div className="container-fluid">
                <div className="card bg-transparent p-3 border-primary rounded-lg">
                    <div className="d-flex justify-content-evenly align-items-center">
                        <div className="fs-3">
                            {userInfo.userId?.lastName} {userInfo.userId?.firstName}
                        </div>
                        <div className="fs-5">
                            Email: {userInfo.userId?.email}
                        </div>
                    </div>
                    <hr/>
                    <div>
                        <div className="d-flex justify-content-start align-items-center">
                            <div className="fs-4 col-3">
                                Your views
                            </div>
                            <div className="px-3 fs-5 border-start col-9">
                                {userInfo?.review}
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div className="py-3 d-flex justify-content-around">
                        <div>
                            <span className="fs-4">Stack Overflow Score: {userInfo?.stackOverflowScore}</span>
                        </div>
                        <div>
                            <span className="fs-4">Github Score: {userInfo.githubScore}</span>
                        </div>
                    </div>
                    <div className="py-3 te">
                        <span className="fs-3">Education Info</span>
                        <div className="card p-2 text-black border-success rounded-lg">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th className="fs-4">Score</th>
                                        <th className="fs-4">Title</th>
                                        <th className="fs-4">Description</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {userInfo.educationScore?.length === 0 ? <tr><td>No Entries</td></tr> : 
                                    userInfo.educationScore?.map((e, i) => (
                                        <tr key={i}>
                                            <td className="fs-5">
                                                {e?.entityScore}
                                            </td>
                                            <td className="fs-5">
                                                {e?.entityTitle}
                                            </td>
                                            <td className="fs-5">
                                                {e?.entityDesc}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="py-3">
                        <span className="fs-3">Work Info</span>
                        <div className="card p-2 text-black border-success rounded-lg">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th className="fs-4">Score</th>
                                        <th className="fs-4">Title</th>
                                        <th className="fs-4">Description</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {userInfo.workScore?.length === 0 ?  <tr><td>No Entries</td></tr> : 
                                    userInfo.workScore?.map((e, i) => (
                                        <tr key={i}>
                                            <td className="fs-5">
                                                {e?.entityScore}
                                            </td>
                                            <td className="fs-5">
                                                {e?.entityTitle}
                                            </td>
                                            <td className="fs-5">
                                                {e?.entityDesc}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="py-3">
                        <span className="fs-3">Other Links</span>
                        <div className="card p-2 text-black border-success rounded-lg">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th className="fs-4">Score</th>
                                        <th className="fs-4">Title</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {userInfo.otherLinkScore?.length ===0 ? <tr><td>No Entries</td></tr> : 
                                    userInfo.otherLinkScore?.map((e, i) => (
                                        <tr key={i}>
                                            <td className="fs-5">
                                                {e?.entityScore}
                                            </td>
                                            <td className="fs-5">
                                                {e?.entityTitle}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SinglePostedReview