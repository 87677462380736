import React from "react";
import { Navigate } from "react-router-dom";
import { useAuthStore } from "../store/store";

// Component to authorize users
export const AuthorizeUser = ({ children }) => {
    const userData = localStorage.getItem('user');
    console.log(userData)
    // If user data is not found in localStorage, redirect to home page
    if (!userData) {
        return <Navigate to={'/'} replace={true}></Navigate>;
    }

    // If user data exists, render children
    return children;
};

// Component to protect routes
export const ProtectRoute = ({ children }) => {
    const user = useAuthStore(state => state.user);
    // console.log(user)
    const currentPath = window.location.pathname;
    // If user is not logged in, redirect to home page
    if (!user) {
        return <Navigate to={'/'} replace={true}></Navigate>;
    }
    if (user.user.isAdvancedAccount && (currentPath === "/dashboard" || currentPath === "/review-lists" || currentPath === "/reviewer-verification")) {
        return <Navigate to={'/advanced-dashboard'} replace={true}></Navigate>;
    } else if (user.user.isReviewer && !user.user.isReviewerVerified && (currentPath === "/dashboard" || currentPath === "/review-lists" || currentPath === "/advanced-dashboard")) {
        return <Navigate to={'/reviewer-verification'} replace={true}></Navigate>;
    } else if (user.user.isReviewer && user.user.isReviewerVerified && (currentPath === "/dashboard" || currentPath === "/reviewer-verification" || currentPath === "/advanced-dashboard")) {
        return <Navigate to={'/review-lists'} replace={true}></Navigate>;
    } else if (!user.user.isAdvancedAccount && !user.user.isReviewer && (currentPath === "/review-lists" || currentPath === "/reviewer-verification")) {
        return <Navigate to={'/dashboard'} replace={true}></Navigate>;
    }
    // If user is logged in, render children
    return children;
};

// Component to set user state from localStorage
export const SetStateProvider = ({ children }) => {
    const setLocalUser = useAuthStore(state => state.setUserFromLocalStorage)
    setLocalUser(JSON.parse(localStorage.getItem('user')))
    return children
}
// Component to prevent certain authentication flows
export const PreventAuthFlow = ({ children }) => {
    const user = useAuthStore(state => state.user);

    // Redirect logic based on user roles and verification status
    if (user) {
        if (user.user.isAdvancedAccount) {
            return <Navigate to={'/advanced-dashboard'} replace={true} />;
        } else if (user.user.isReviewer && !user.user.isReviewerVerified) {
            return <Navigate to={'/reviewer-verification'} replace={true} />;
        } else if (user.user.isReviewer && user.user.isReviewerVerified) {
            return <Navigate to={'/review-lists'} replace={true} />;
        } else {
            return <Navigate to={'/dashboard'} replace={true} />;
        }
    } else {
        // If user is not logged in, render children
        return children;
    }
};

// Component to prevent non-verified reviewers from accessing certain routes
export const PreventNonVerifiedReviewers = ({ children }) => {
    const user = useAuthStore(state => state.user);

    // Redirect non-verified reviewers to reviewer verification page
    if (user && user.user.isReviewer && !user.user.isReviewerVerified) {
        return <Navigate to={'/reviewer-verification'} replace={true} />;
    } else {
        // Render children for other users or when user is not logged in
        return children;
    }
};

// Component to prevent users without advanced accounts from accessing certain routes
export const AllowAdvancedAccount = ({ children }) => {
    const user = useAuthStore(state => state.user);
    // console.log("h vbipo", user.user.isAdvancedAccount)

    // Redirect users without advanced accounts to dashboard
    if (user && !user.user.isAdvancedAccount) {
        return <Navigate to={"/dashboard"} replace={true} />;
    } else {
        // Render children for other users or when user is not logged in
        return children;
    }
};
