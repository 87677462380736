import { Link, useLocation } from "react-router-dom"
import { useOutletContext } from "react-router-dom"
const UserProjectTasks = () => {

    const projectId = useLocation().state
    
    const [displayOutlet, setDisplayOutlet] = useOutletContext()

    return (
        <div>
            UserProjectTasks
            <Link to={`/dashboard/teams-joined/single-project/${projectId}` } >
                <button onClick={() => setDisplayOutlet(false)} className="btn btn-warning">
                    Back
                </button>
            </Link>
        </div>
    )
}

export default UserProjectTasks