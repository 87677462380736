import { Link } from "react-router-dom"

const SiteUnderDev = () => {
    return (
        <div>
            <div className="text-center display-1">
                Site is under development, Please Stay tuned for further Updated
            </div>
            <div align="center">
                <Link to="/">
                    <button className="btn btn-outline-secondary">
                        Go Back
                    </button>
                </Link>
            </div>
        </div>
    )
}

export default SiteUnderDev