import { TextField } from '@mui/material';
import students from '../authFlow/assets/student_page.png'
import Navbar from '../authFlow/Navbar';
import { useAuthStore } from '../store/store';
import { fetchEmail } from '../actions/authActions';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import Footer from './Footer';

const Students = () => {

    const userData = useAuthStore(state => state.user)
    const setStateUser = useAuthStore(state => state.setUser)
    const navigate = useNavigate()

    const [values, setValues] = useState({
        email: '',
        error: '',
        errorMessage: ''
    })

    const { email, errorMessage } = values

    const emailRegex = /\S+@\S+\.\S+/;

    const handleChange = name => event => {
        setValues({ ...values, error: '', errorMessage: '', [name]: event.target.value });
    };

    const handleSubmit = (email) => {
        if (emailRegex.test(email)) {
            fetchEmail({ email: email }).then(res => {
                if (res.userExist === false) {
                    navigate('/register', { state: { sessionId: res.sessionId } })
                }
                if (res.userExist === true) {
                    navigate('/login', { state: { sessionId: res.sessionId } })
                }
            })
        } else {
            setValues({ ...values, errorMessage: "Please enter a valid Email" })
        }
    }

    const onClickLogout = () => {
        setStateUser(null)
        localStorage.removeItem('user')
    }

    return (
        <>
            <div className='py-2 sticky-top'>
                <Navbar />
            </div>
            <div className="container-fluid d-flex flex-wrap justify-content-center py-5">
                <div className="col-11">
                    <div className='fs-1 d-lg-block d-none pb-3 fw-bold text-center'data-aos="fade-up" data-aos-delay="500">
                        Students
                    </div>
                    <div className="d-flex flex-wrap justify-content-center align-items-center">
                        <div className="col-lg-5 px-md-5">
                            <img src={students} className="img-fluid" alt="devSemble Logo" data-aos="fade-right" data-aos-delay="500" />
                            <div className='fs-1 d-lg-none d-block pb-3 fw-bold text-center'data-aos="fade-up" data-aos-delay="00">
                                Students
                            </div>
                        </div>
                        <div className="col-lg-6 px-md-5">
                            <div className="fs-3">
                                <div className='py-2' data-aos="fade-up" data-aos-delay="200"><span className='text-primary fw-bolder'><b>devSemble</b></span> offers students a unique opportunity to gain practical experience and build their portfolios.
                                </div>
                                <div className='py-2' data-aos="fade-up" data-aos-delay="300">
                                    Connect with real-world projects, apply your learning in practical scenarios, and showcase your skills to potential employers.
                                </div>
                                <div className='py-2' data-aos="fade-up" data-aos-delay="400">
                                    Join our community of students and start your journey to professional success.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id='get-started-section' className='py-5 d-flex justify-content-center align-items-center'>
                {userData === null ?
                    <div className='col-12 col-md-6 card border-0 bg-transparent rounded-lg p-4'>
                        <div className='text-center py-3 fs-3'>
                            Get Started Today!
                        </div>
                        <TextField
                            required={true}
                            className='m-0'
                            error={errorMessage === "" ? false : true}
                            type='email'
                            value={email}
                            label={errorMessage === '' ? "Email" : errorMessage}
                            onChange={handleChange('email')}
                            variant="outlined" />
                        <button
                            className='btn btn-primary rounded-pill my-3'
                            onClick={() => {
                                handleSubmit(email)
                            }}>Get Started</button>
                    </div> : <div align='center'>
                        <h1>User Exist</h1>
                        {userData?.user.isReviewerVerified === false && userData?.user.isReviewer === true ?
                            <Link to="/reviewer-verification">
                                <button className='btn btn-secondary'>
                                    Complete Your Reviewer Verification
                                </button>
                            </Link> : null}
                        {userData?.user.isAdvancedAccount === true ?
                            <Link to="/advanced-dashboard">
                                <button className='btn btn-secondary'>
                                    Advanced Dashboard
                                </button>
                            </Link> : null}
                        {userData?.user.isReviewerVerified === true && userData.user.isReviewer === true ?
                            <Link to="/review-lists">
                                <button className='btn btn-secondary'>
                                    Review Dashboard
                                </button>
                            </Link> : null}
                        {userData?.user.isReviewer === false && userData.user.isAdvancedAccount === false ?
                            <Link to="/dashboard">
                                <button className='btn btn-secondary'>
                                    Dashboard
                                </button>
                            </Link> : null}
                        <button className='btn btn-danger'
                            onClick={() => onClickLogout()}
                        >Log-Out</button>
                    </div>}
            </div>
            <div className='pt-5'>
                <Footer />
            </div>
        </>
    )
}

export default Students