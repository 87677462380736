import {apiUrl} from "../constants"

export const updateAdvAccount = (data) => {
    return fetch(`${apiUrl}/update-advanced-account/${data.userId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-type': 'application/json'
        },
        body: JSON.stringify(data)
    }).then(response => {
        return response.json()
    }).catch(err => {
        return err.json()
    })
}