import { useNavigate, useLocation } from 'react-router-dom';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { loginUser } from '../actions/authActions';
import { useAuthStore } from '../store/store';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const LanderPage = () => {

    // useAuthStore(state => console.log(state))
    const setStateUser = useAuthStore(state => state.setUser)
    // const data = useAuthStore(state => state.user)
    const [showPassword, setShowPassword] = useState(false);

    const [values, setValues] = useState({
        password: '',
        error: '',
        errorMessage: ''
    })

    const { state } = useLocation();

    useEffect(() => {
        if (state === null) {
            return navigate('/')
        }
    })

    const navigate = useNavigate()

    // var passwordRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;

    const { password, error, errorMessage } = values

    const handleSubmit = (password) => {
        if (password !== '') {
            let data = {
                sessionId: state.sessionId,
                password: password
            }
            loginUser(data).then(res => {
                if (res.status === false) {
                    return setValues({ ...values, error: true, errorMessage: res.error })
                }
                if (res.status === true) {
                    setStateUser(res)
                    localStorage.setItem('user', JSON.stringify(res))
                    if (res.user.isAdvancedAccount) {
                        return navigate('/advanced-dashboard')
                    } else {
                        return navigate('/dashboard')
                    }
                }
            }).catch(err => {
                setValues({ ...values, error: true, errorMessage: "Something went wrong" })
                console.log(err)
            })
        } else {
            setValues({ ...values, errorMessage: "Please provide valid data" })
        }
    }

    const handleChange = name => event => {
        setValues({ ...values, error: '', errorMessage: '', [name]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className='vh-100 d-flex justify-content-center align-items-center'>
            <div className='col-10 col-lg-4 card border-0 shadow bg-transparent rounded-lg p-4' data-aos="fade-left">
                <a href='/#get-started-section' className="" style={{ textDecoration: 'none' }}>
                    <button className="btn fw-bold btn-warning rounded-pill">
                        <FontAwesomeIcon size="1x" icon={faArrowLeft} /><span className="ps-lg-3">Back</span>
                    </button>
                </a>
                <h1>Login</h1>
                {error ? <div align="center" className='text-danger'>{errorMessage}</div> : null}
                <TextField
                    className='my-2'
                    error={errorMessage === "" ? false : true}
                    type={showPassword ? "text" : "password"}
                    value={password}
                    label={errorMessage === '' ? "Password" : errorMessage}
                    onChange={handleChange('password')}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    variant="outlined" />
                <button
                    className='btn btn-outline-primary my-3'
                    onClick={() => {
                        handleSubmit(password)
                    }}>Login</button>
            </div>
        </div>
    )
}

export default LanderPage