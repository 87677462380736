import {apiUrl} from './../constants'

export const postGitCreds = (data) => {
    return fetch(`${apiUrl}/post-git-creds`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    }).then(response => {
        return response.json()
    }).catch(err =>
        console.log("GIT TOKEN Err: ", err)
    )
}

export const getRepos = (userId) => {
    return fetch(`${apiUrl}/get-git-repos/${userId}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(response => {
        return response.json()
    }).catch(err =>  {
        return err.json()
    })
}